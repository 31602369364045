import useAppQuery from 'hooks/useAppQuery';
import useAppUser from 'hooks/useAppUser';
import useNotify from 'hooks/useNotify';
import { useTranslation } from 'react-i18next';
import API from 'services/API';

const useReferLink = () => {
  const { t } = useTranslation();
  const { notifyError } = useNotify();
  const { user, isEmployee } = useAppUser();

  const { data: referLink, isLoading } = useAppQuery(
    ['get-refer-friend-link', user?.email],
    async () => {
      const response = await API.post<{
        rdata: string;
      }>(
        `https://api.partnerstack.com/v1/partnerships/anonymous/hostfully/${user.email}`,
      );
      const rdata = JSON.parse(response.data?.rdata);
      return rdata.link;
    },
    {
      enabled: isEmployee,
      onError: () => notifyError(t('pageAccount.somethingWentWrong')),
    },
  );

  return {
    referLink,
    isLoading,
  };
};

export default useReferLink;
