import { parseISO } from 'date-fns';
import { SortingState } from '@tanstack/react-table';
import {
  GetOwnerAdjustmentsSchedulesResponseTO,
  OwnerAdjustmentSchedule,
} from 'models/ownerAdjustments/OwnerAdjustmentSchedule';
import { ExpectedQueryData } from 'components/table/hooks/useServerFilteredTable';
import API from 'services/API';
import { getQueryParams } from './common/OwnerAdjustmentsTable.utils';

export const RECURRING_ADJUSTMENTS_BASE_QUERY_KEY =
  'owner-adjustment-schedules';

export const recurringAdjustmentsQuery = ({
  propertyUid,
  pageIndex,
  pageSize,
  sorting,
}: {
  propertyUid: string;
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
}) => ({
  queryKey: [
    RECURRING_ADJUSTMENTS_BASE_QUERY_KEY,
    propertyUid,
    { pageIndex, sorting },
  ],
  queryFn: async (): Promise<ExpectedQueryData<OwnerAdjustmentSchedule>> => {
    const queryParams = getQueryParams({
      propertyUid,
      pageIndex,
      pageSize,
      sorting,
    });

    const response = await API.get<GetOwnerAdjustmentsSchedulesResponseTO>(
      `/api/internal/owner-adjustments/schedules?${queryParams}`,
    );

    const { ownerAdjustmentSchedules, _metadata } = response.data;

    return {
      _metadata,
      data: ownerAdjustmentSchedules.map((ownerAdjustment) => ({
        ...ownerAdjustment,
        created: ownerAdjustment.created
          ? parseISO(ownerAdjustment.created)
          : null,
        endRecurringSchedule: ownerAdjustment.endRecurringSchedule
          ? parseISO(ownerAdjustment.endRecurringSchedule)
          : null,
        nextScheduled: parseISO(ownerAdjustment.nextScheduled),
      })),
    };
  },
  enabled: true,
});
