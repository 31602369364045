import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { Lead } from 'models/Leads';
import { Col } from 'react-bootstrap';
import { HasFeeItem, HasOrderItems } from 'models/hasOrderItems/HasOrderItems';
import { isLeadImported, showCleaningFeeRow } from 'utils/lead/leadUtils';
import { QuoteFeeType } from 'models/Quote';
import { useFormContext } from 'react-hook-form';
import TextField from 'components/domain/lead/modal/tabs/details/order/fields/common/TextField';
import { OrderViewTableHeader } from '../OrderView.styles';
import {
  OrderViewPriceDetailsListWrapper,
  PriceDetailsListTotal,
} from './OrderViewPriceDetails.styles';
import OrderViewPriceDetailsListItemTotal from './OrderViewPriceDetailsListItemTotal';
import Rent from './items/Rent';
import Taxes from './items/Taxes';
import Fees from './items/Fees';
import Services from './items/Services';
import SecurityDeposit from './items/SecurityDeposit';
import AdjustmentItems from './items/AdjustmentItems';
import AddOrderAdjustmentButton from './actions/AddOrderAdjustmentButton';
import { PriceDetailsItem } from './OrderViewPriceDetailsListItemRow';
import OrderViewTotalSubtitle from './OrderViewTotalSubtitle';

const OrderViewPriceDetailsInner = ({
  order,
  lead,
  handleOrderAdjustment,
  showTitle,
  allowEdit,
  isLoading,
  showAmountPaidRow,
  showBalanceRow,
}: {
  order: HasOrderItems;
  lead: Lead;
  handleOrderAdjustment?: boolean;
  showTitle?: boolean;
  allowEdit?: boolean;
  isLoading?: boolean;
  showAmountPaidRow?: boolean;
  showBalanceRow?: boolean;
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  const {
    rent,
    uid: orderUid,
    includeSecurityDepositInTotal,
    securityDeposit,
    totalAmount,
    fees,
    services,
    adjustmentItems,
    currency,
  } = order || {};

  const balance = lead?.order?.balance ?? 0;
  const balanceDueLocalDate = lead?.order?.balanceDueLocalDate ?? null;
  const fullPaymentTiming = lead?.property?.pricing?.fullPaymentTiming ?? 0;

  const isImported = isLeadImported(lead);

  const onItemUpdated = (otherFees, oldValue, newValue) => {
    const oldFeeIndex = otherFees.findIndex(
      (fee) => fee.feeId === null && fee.name === oldValue,
    );
    otherFees[oldFeeIndex].removed = true;

    otherFees.push({
      ...otherFees[oldFeeIndex],
      name: newValue,
      removed: false,
    });

    setValue('overrides.fees.otherFees', otherFees);

    setValue(`quote.fees.otherFees`, otherFees, {
      shouldDirty: true,
    });
  };

  const getPriceDetailsItemForFee = (
    otherFees: HasFeeItem[],
    fee: HasFeeItem,
    currentIndex,
  ): PriceDetailsItem => {
    const feeItem: PriceDetailsItem = {
      label:
        !fee.feeUid && allowEdit && fee.isEditable ? (
          <TextField
            key={`${fee.name}-fee-${fee.feeUid || currentIndex}`}
            value={fee.name}
            isEditable
            onItemUpdated={(_val, newVal) => {
              if (_val !== newVal) {
                onItemUpdated([...otherFees], _val, newVal);
              }
            }}
          />
        ) : (
          fee.name
        ),
      amount: fee.netPrice,
      currency,
      onDelete:
        fee.feeId === null && fee.isEditable && allowEdit
          ? () => {
              setValue(`quote.fees.otherFees[${currentIndex}].removed`, true, {
                shouldDirty: true,
              });
            }
          : null,
      isEditable: allowEdit && fee.isEditable,
      onItemUpdated: (val) => {
        setValue(`quote.fees.otherFees[${currentIndex}].netPrice`, val, {
          shouldDirty: true,
        });
      },
    };

    return feeItem;
  };

  const getTaxFees = (otherFees: HasFeeItem[]): PriceDetailsItem[] => {
    return otherFees?.reduce(
      (acc: PriceDetailsItem[], fee: HasFeeItem, currentIndex) => {
        if (fee.type === QuoteFeeType.TAX) {
          const feeItem = getPriceDetailsItemForFee(
            otherFees,
            fee,
            currentIndex,
          );
          acc.push(feeItem);
        }

        if (fee.taxAmount > 0) {
          const feeTaxItem: PriceDetailsItem = {
            label: t('componentOrder.otherFeeTax', {
              name: fee.name,
              tax: fee.taxationRate,
            }),
            amount: fee.taxAmount,
            currency,
          };

          acc.push(feeTaxItem);
        }

        return acc;
      },
      [],
    );
  };

  const getFees = (otherFees: HasFeeItem[]): PriceDetailsItem[] => {
    return otherFees.reduce(
      (acc: PriceDetailsItem[], fee: HasFeeItem, currentIndex) => {
        if (fee.removed) {
          return acc;
        }

        if (fee.type === QuoteFeeType.TAX) {
          return acc;
        }

        const feeItem = getPriceDetailsItemForFee(otherFees, fee, currentIndex);
        acc.push(feeItem);

        return acc;
      },
      [],
    );
  };

  return (
    <div>
      <OrderViewTableHeader>
        {showTitle && (
          <Col xs={6}>
            <h4 data-testid="price-details-title">
              {t('pageOrder.priceDetails.sectionTitle')}
            </h4>
          </Col>
        )}
        <Col xs={showTitle ? 6 : 12} className="text-right">
          {!isImported && handleOrderAdjustment && (
            <AddOrderAdjustmentButton
              orderUid={orderUid}
              leadUid={lead.uid}
              currency={currency}
            />
          )}
        </Col>
      </OrderViewTableHeader>

      <OrderViewPriceDetailsListWrapper
        $isLoading={isLoading}
        data-testid="price-details-wrapper"
        className="price-details-wrapper"
      >
        {!!rent && (
          <Rent
            allowEdit={allowEdit}
            order={order}
            lead={lead}
            isImported={isImported}
          />
        )}
        {!isImported && (
          <Taxes
            priceDetailsItems={getTaxFees(fees?.otherFees)}
            allowEdit={allowEdit}
            order={order}
          />
        )}
        {!isImported &&
          (fees?.otherFees.find((o) => o.type !== QuoteFeeType.TAX) ||
            showCleaningFeeRow({
              lead,
              cleaningFee: fees?.cleaningFee,
              allowEdit,
            })) && (
            <Fees
              priceDetailsItems={getFees(fees?.otherFees)}
              lead={lead}
              allowEdit={allowEdit}
              order={order}
            />
          )}
        {!isImported && services?.length > 0 && <Services order={order} />}
        {!isImported && adjustmentItems?.length > 0 && (
          <AdjustmentItems
            handleOrderAdjustment={handleOrderAdjustment}
            lead={lead}
            order={order}
          />
        )}

        {!isImported && includeSecurityDepositInTotal && !!securityDeposit && (
          <SecurityDeposit
            includeSecurityDepositInTotal={includeSecurityDepositInTotal}
            allowEdit={allowEdit}
            order={order}
          />
        )}

        <PriceDetailsListTotal
          className="price-details-list-total"
          data-testid="price-details-list-total"
        >
          <OrderViewPriceDetailsListItemTotal
            currency={currency}
            data-testid="price-details-list-item-total-total"
            label={
              isImported
                ? t('componentOrder.payout')
                : t('componentOrder.total')
            }
            subtitle={!showBalanceRow && <OrderViewTotalSubtitle lead={lead} />}
            amount={totalAmount}
          />

          {showAmountPaidRow && totalAmount - balance > 0 && !isImported && (
            <OrderViewPriceDetailsListItemTotal
              currency={currency}
              data-testid="price-details-list-item-total-amountPaid"
              label={t('componentOrder.amountPaid')}
              amount={totalAmount - balance}
            />
          )}

          {showBalanceRow && !isImported && balance > 0 && (
            <OrderViewPriceDetailsListItemTotal
              currency={currency}
              data-testid="price-details-list-item-total-balance"
              label={t('componentOrder.balanceDue')}
              amount={balance}
              subtitle={
                balanceDueLocalDate &&
                t('componentOrder.balanceDueDate', {
                  date: format(parseISO(balanceDueLocalDate), 'MMM dd, yyyy'),
                  days: fullPaymentTiming,
                })
              }
            />
          )}
        </PriceDetailsListTotal>

        {!isImported &&
          !includeSecurityDepositInTotal &&
          securityDeposit > 0 && (
            <SecurityDeposit
              includeSecurityDepositInTotal={includeSecurityDepositInTotal}
              allowEdit={allowEdit}
              order={order}
            />
          )}
      </OrderViewPriceDetailsListWrapper>
    </div>
  );
};

OrderViewPriceDetailsInner.defaultProps = {
  handleOrderAdjustment: true,
  showAmountPaidRow: true,
  showBalanceRow: true,
  showTitle: true,
  allowEdit: false,
  isLoading: false,
};

export default OrderViewPriceDetailsInner;
