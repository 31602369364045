import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import { PropertyBusinessType } from 'models/Properties';
import useAppMutation from 'hooks/useAppMutation';
import { useTranslation } from 'react-i18next';
import useNotify from 'hooks/useNotify';
import API from 'services/API';
import useInvalidatePropertyPhotos from '../../hooks/useInvalidatePropertyPhotos';
import usePropertyPhotosData from '../../hooks/usePropertyPhotosData';
import { ACCEPT_FILE_TYPES, MAX_PHOTOS_LIMIT } from './UploadPhoto.utils';

export const UPLOAD_PHOTO_MUTATION_KEY = ['uploadPhoto'];

const useUploadPhotos = () => {
  const { property } = usePropertySettingsInitData();
  const { t } = useTranslation();
  const { notifyError } = useNotify();
  const { invalidatePropertyPhotos } = useInvalidatePropertyPhotos();
  const { getPhotos } = usePropertyPhotosData();

  const isPropertyHotel = property?.businessType === PropertyBusinessType.HOTEL;

  const { mutateAsync: uploadPhotoToProperty } = useAppMutation(
    UPLOAD_PHOTO_MUTATION_KEY,
    (file: File) => {
      const formData = new FormData();
      formData.append('file', file);

      return API.post('/api/internal/photos/property-photo-create', formData, {
        params: {
          [isPropertyHotel ? 'hotelUid' : 'propertyUid']: property.uid,
        },
      });
    },
    {
      onError: (_, file) => {
        notifyError(
          t('pageProperty.photos.uploadError', { fileName: file.name }),
        );
      },
    },
  );

  const handleImagesSelect = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { files } = event.target;
    if (!files?.length) return;

    const fileArray = Array.from(files);

    const resetInput = () => {
      event.target.value = '';
    };

    // check if the file type is valid:
    const invalidFile = fileArray.find(
      (file) => !ACCEPT_FILE_TYPES.includes(file.type),
    );
    if (invalidFile) {
      notifyError(t('pageProperty.photos.invalidFileType'));
      resetInput();
      return;
    }

    // check if the amount of existing photos + the amount of new photos is greater than the max amount of photos
    const photos = getPhotos();
    const photosCount = photos.length || 0;

    if (photosCount + fileArray.length > MAX_PHOTOS_LIMIT) {
      notifyError(
        t('pageProperty.photos.maxPhotosReached', {
          count: MAX_PHOTOS_LIMIT - photosCount,
        }),
      );
      resetInput();
      return;
    }

    await Promise.allSettled(
      fileArray.map((file) => uploadPhotoToProperty(file)),
    );

    resetInput();
    invalidatePropertyPhotos();
  };

  return {
    handleImagesSelect,
  };
};

export default useUploadPhotos;
