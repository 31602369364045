import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const ViewAirbnbGuestReviewModalContainer = styled.div``;

export const DisplayFieldRowStyled = styled(Row)`
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  & label {
    float: right;
  }
`;

export const OverallRatingStyled = styled.div`
  span {
    font-size: 16px;
    font-weight: 600;
  }

  display: flex;
  align-items: center;
  gap: 5px;
`;

export const ResponseTimeExpiredStyled = styled.p`
  display: flex;
  align-items: center;
  gap: 8px;

  color: #6c757d;
`;

export const ResponseSubmittedOnStyled = styled.p`
  color: #6c757d;

  text-align: right;
`;
