import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';
import useNotify from 'hooks/useNotify';
import { Photo } from 'models/Photos';
import useAppMutation from 'hooks/useAppMutation';
import API from 'services/API';
import useInvalidatePropertyPhotos from 'pages/property/photos/hooks/useInvalidatePropertyPhotos';
import PhotoEditModal from '../modals/editPhoto/PhotoEditModal';
import { PhotoEditModalId } from '../modals/editPhoto/PhotoEditModal.constants';

export const DELETE_PHOTO_MUTATION_KEY = ['deletePhoto'];

const usePhotoActions = (photo: Photo) => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();
  const { invalidatePropertyPhotos } = useInvalidatePropertyPhotos();
  const { openModal, openConfirmModal } = useAppModal();

  const { mutate: deletePhoto } = useAppMutation(
    DELETE_PHOTO_MUTATION_KEY,
    () => {
      return API.delete(`/api/v3.2/photos/${photo.uid}`);
    },
    {
      onError: () => {
        notifyError(t('pageProperty.photos.deleteModal.error'));
      },
      onSettled: () => {
        invalidatePropertyPhotos();
      },
    },
  );

  const handleEditPhoto = () => {
    openModal({
      id: PhotoEditModalId,
      customContent: <PhotoEditModal photo={photo} />,
      size: 'large',
    });
  };

  const handleMovePhotoToTop = () => {
    // TODO: to be implemented
  };

  const handleDeletePhoto = () => {
    openConfirmModal({
      title: t('pageProperty.photos.deleteModal.title'),
      body: t('pageProperty.photos.deleteModal.description'),
      onConfirm: deletePhoto,
      confirmLabel: t('common.delete'),
      confirmColor: 'danger',
    });
  };

  return { handleMovePhotoToTop, handleDeletePhoto, handleEditPhoto };
};

export default usePhotoActions;
