import { LanguageCode } from '../i18n/internationalization.types';
import { BaseResponse } from './_base';
import { Order, OwnerCommission } from './Orders';
import { PinCodeLead } from './PinCode';
import { ReviewByHost } from './Reviews';
import { LeadQuoteOverride } from './Quote';
import { Property } from './Properties';

export enum LeadSource {
  /* eslint-disable no-unused-vars */
  ORBIRENTAL_FORM = 'ORBIRENTAL_FORM',
  ORBIRENTAL_WIDGET = 'ORBIRENTAL_WIDGET',
  ORBIRENTAL_API = 'ORBIRENTAL_API',
  OWNER_PORTAL = 'OWNER_PORTAL',
  IMPORT_AIRBNB = 'IMPORT_AIRBNB',
  IMPORT_HOMEAWAY = 'IMPORT_HOMEAWAY',
  IMPORT_VRBO = 'IMPORT_VRBO',
  IMPORT_BOOKINGCOM = 'IMPORT_BOOKINGCOM',
  IMPORT_ABRITEL = 'IMPORT_ABRITEL',
  IMPORT_TRIPADVISOR = 'IMPORT_TRIPADVISOR',
  IMPORT_OTHER_PROVIDER = 'IMPORT_OTHER_PROVIDER',
  AIRBNB_BLOCK_IMPORT = 'AIRBNB_BLOCK_IMPORT',
  DIRECT_TRIPPING = 'DIRECT_TRIPPING',
  DIRECT_HOMETOGO = 'DIRECT_HOMETOGO',
  DIRECT_BOOKINGDOTCOM = 'DIRECT_BOOKINGDOTCOM',
  DIRECT_AIRBNB = 'DIRECT_AIRBNB',
  DIRECT_EXPEDIA = 'DIRECT_EXPEDIA',
  DIRECT_HOLIDU = 'DIRECT_HOLIDU',
  DIRECT_HOMEAWAY = 'DIRECT_HOMEAWAY',
  DIRECT_HVMI = 'DIRECT_HVMI',
  DIRECT_REDAWNING = 'DIRECT_REDAWNING',
  MisterBnB = 'MisterBnB',
  ORBIRENTAL_LINKED = 'ORBIRENTAL_LINKED',
  DIRECT_GOOGLE = 'DIRECT_GOOGLE',
  /* eslint-enable no-unused-vars */
}

export enum LeadStatus {
  /* eslint-disable no-unused-vars */
  NEW = 'NEW',
  ON_HOLD = 'ON_HOLD',
  HOLD_EXPIRED = 'HOLD_EXPIRED',
  BOOKED_BY_AGENT = 'BOOKED_BY_AGENT',
  BLOCKED = 'BLOCKED',
  BOOKED_BY_CUSTOMER = 'BOOKED_BY_CUSTOMER',
  BOOKED_BY_OWNER = 'BOOKED_BY_OWNER',
  QUOTE_SENT = 'QUOTE_SENT',
  DECLINED = 'DECLINED',
  CANCELLED = 'CANCELLED',
  IGNORED = 'IGNORED',
  ARCHIVED = 'ARCHIVED',
  STAY = 'STAY',
  PAID_IN_FULL = 'PAID_IN_FULL',
  DUPLICATE = 'DUPLICATE',
  SAMPLE = 'SAMPLE',
  BOOKED_EXTERNALLY = 'BOOKED_EXTERNALLY',
  CLOSED_QUOTE = 'CLOSED_QUOTE',
  CLOSED_HOLD = 'CLOSED_HOLD',
  PENDING = 'PENDING',
  CANCELLED_BY_TRAVELER = 'CANCELLED_BY_TRAVELER',
  CANCELLED_BY_OWNER = 'CANCELLED_BY_OWNER',
  /* eslint-enable no-unused-vars */
}

export enum LeadGroupedStatus {
  RELEVANT = 'RELEVANT',
  QUOTE = 'QUOTE',
  BOOKED = 'BOOKED',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  STAY = 'STAY',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
  ARCHIVED = 'ARCHIVED',
}

export enum LeadType {
  BLOCK = 'BLOCK',
  INQUIRY = 'INQUIRY',
  BOOKING = 'BOOKING',
}

export enum LeadChannel {
  /* eslint-disable no-unused-vars */
  HOSTFULLY = 'HOSTFULLY',
  AIRBNB = 'AIRBNB',
  BOOKING_COM = 'BOOKING_COM',
  VRBO = 'VRBO',
  HVMI = 'HVMI',
  GOOGLE = 'GOOGLE',
  FIND_RENTALS = 'FIND_RENTALS',
  GOLIGHTLY = 'GOLIGHTLY',
  HOMADS = 'HOMADS',
  HOMETOGO = 'HOMETOGO',
  TRIP_ADVISOR = 'TRIP_ADVISOR',
  VACAYMYMAY = 'VACAYMYMAY',
  HOLIDU = 'HOLIDU',
  STAYMENTITY = 'STAYMENTITY',
  GETAWAYGOGO = 'GETAWAYGOGO',
  EVERYSTAY = 'EVERYSTAY',
  EGETINNZ = 'EGETINNZ',
  REDAWNING = 'REDAWNING',
  MISTERBNB = 'MISTERBNB',
  WHIMSTAY = 'WHIMSTAY',
  NEXTPAX = 'NEXTPAX',
  RENTALS_UNITED = 'RENTALS_UNITED',
  MONAKER_GROUP = 'MONAKER_GROUP',
  /* eslint-enable no-unused-vars */
}

export enum LeadSortingStrategy {
  CHECK_IN_DATE_ASC = 'CHECK_IN_DATE_ASC',
  CHECK_IN_DATE_DESC = 'CHECK_IN_DATE_DESC',
  CREATION_DATE_DESC = 'CREATION_DATE_DESC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
}

export const LeadChannelNameMap = new Map<LeadChannel, string>([
  [LeadChannel.HOSTFULLY, 'Hostfully'],
  [LeadChannel.AIRBNB, 'Airbnb'],
  [LeadChannel.BOOKING_COM, 'Booking.com'],
  [LeadChannel.VRBO, 'Vrbo'],
  [LeadChannel.HVMI, 'HVMI'],
  [LeadChannel.GOOGLE, 'Google'],
  [LeadChannel.FIND_RENTALS, 'Find Rentals'],
  [LeadChannel.GOLIGHTLY, 'GoLightly'],
  [LeadChannel.HOMADS, 'Homads'],
  [LeadChannel.HOMETOGO, 'HomeToGo'],
  [LeadChannel.TRIP_ADVISOR, 'TripAdvisor'],
  [LeadChannel.VACAYMYMAY, 'VacayMyWay'],
  [LeadChannel.HOLIDU, 'Holidu'],
  [LeadChannel.STAYMENTITY, 'Staymenity'],
  [LeadChannel.GETAWAYGOGO, 'GetawayGoGo'],
  [LeadChannel.EVERYSTAY, 'Everystay'],
  [LeadChannel.EGETINNZ, 'eGetinnz'],
  [LeadChannel.REDAWNING, 'RedAwning'],
  [LeadChannel.MISTERBNB, 'MisterBnb'],
  [LeadChannel.WHIMSTAY, 'Whimstay'],
  [LeadChannel.NEXTPAX, 'Nextpax'],
  [LeadChannel.RENTALS_UNITED, 'Rentals United'],
  [LeadChannel.MONAKER_GROUP, 'Monaker Group'],
]);

export const ChannelToSourceMap = new Map<LeadChannel, LeadSource>([
  [LeadChannel.HOMETOGO, LeadSource.DIRECT_HOMETOGO],
  [LeadChannel.TRIP_ADVISOR, LeadSource.IMPORT_TRIPADVISOR],
  [LeadChannel.BOOKING_COM, LeadSource.DIRECT_BOOKINGDOTCOM],
  [LeadChannel.AIRBNB, LeadSource.DIRECT_AIRBNB],
  [LeadChannel.HOLIDU, LeadSource.DIRECT_HOLIDU],
  [LeadChannel.GOOGLE, LeadSource.DIRECT_GOOGLE],
  [LeadChannel.MISTERBNB, LeadSource.MisterBnB],
  [LeadChannel.REDAWNING, LeadSource.DIRECT_REDAWNING],
  [LeadChannel.HVMI, LeadSource.DIRECT_HVMI],
  [LeadChannel.VRBO, LeadSource.DIRECT_HOMEAWAY],
]);

export const SourceColor = {
  HOSTFULLY: '#FFC454',
  HOMEAWAY: '#245ABC',
  TRIP_ADVISOR: '#34E0A1',
  TRIPPING: '#F37920',
  HOMETOGO: '#612ACE',
  BOOKINGDOTCOM: '#003580',
  AIRBNB: '#ff5a5f',
  HOLIDU: '#3567B0',
  HVMI: '#F69464',
  GOOGLE: '#33A852',
  DEFAULT: '#3ECB9A',
};

export const SourceToColorMap = new Map<LeadSource, string>([
  [LeadSource.ORBIRENTAL_FORM, SourceColor.HOSTFULLY],
  [LeadSource.ORBIRENTAL_WIDGET, SourceColor.HOSTFULLY],
  [LeadSource.ORBIRENTAL_API, SourceColor.HOSTFULLY],
  [LeadSource.OWNER_PORTAL, SourceColor.HOSTFULLY],
  [LeadSource.ORBIRENTAL_LINKED, SourceColor.HOSTFULLY],
  [LeadSource.IMPORT_HOMEAWAY, SourceColor.HOMEAWAY],
  [LeadSource.IMPORT_VRBO, SourceColor.HOMEAWAY],
  [LeadSource.IMPORT_ABRITEL, SourceColor.HOMEAWAY],
  [LeadSource.DIRECT_HOMEAWAY, SourceColor.HOMEAWAY],
  [LeadSource.IMPORT_TRIPADVISOR, SourceColor.TRIP_ADVISOR],
  [LeadSource.DIRECT_TRIPPING, SourceColor.TRIPPING],
  [LeadSource.DIRECT_HOMETOGO, SourceColor.HOMETOGO],
  [LeadSource.IMPORT_BOOKINGCOM, SourceColor.BOOKINGDOTCOM],
  [LeadSource.DIRECT_BOOKINGDOTCOM, SourceColor.BOOKINGDOTCOM],
  [LeadSource.AIRBNB_BLOCK_IMPORT, SourceColor.AIRBNB],
  [LeadSource.IMPORT_AIRBNB, SourceColor.AIRBNB],
  [LeadSource.DIRECT_AIRBNB, SourceColor.AIRBNB],
  [LeadSource.DIRECT_HOLIDU, SourceColor.HOLIDU],
  [LeadSource.DIRECT_HVMI, SourceColor.HVMI],
  [LeadSource.DIRECT_GOOGLE, SourceColor.GOOGLE],
]);

export enum LeadCircleColor {
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
  RED = 'RED',
  BLUE = 'BLUE',
}

export enum LeadAvailablePipelineAction {
  ACCEPT_PENDING = 'ACCEPT_PENDING',
  AIRBNB_PRE_APPROVE = 'AIRBNB_PRE_APPROVE',
  ASSIGN_TO_ME = 'ASSIGN_TO_ME',
  CANCEL_BOOKING = 'CANCEL_BOOKING',
  CLOSE_LEAD = 'CLOSE_LEAD',
  DECLINE_PENDING = 'DECLINE_PENDING',
  EXTEND_HOLD = 'EXTEND_HOLD',
  IGNORE_LEAD = 'IGNORE_LEAD',
  MARK_AS_BOOKED = 'MARK_AS_BOOKED',
  MARK_AS_PAID = 'MARK_AS_PAID',
  OPEN_INBOX = 'OPEN_INBOX',
  PLACE_ON_HOLD = 'PLACE_ON_HOLD',
  REBOOK = 'REBOOK',
  REBOOK_AS_NEW_LEAD = 'REBOOK_AS_NEW_LEAD',
  RECORD_PAYMENT = 'RECORD_PAYMENT',
  REOPEN = 'REOPEN',
  REVIEW = 'REVIEW',
  REVOKE_HOLD = 'REVOKE_HOLD',
  SEND_ALTERNATIVE_OPTIONS = 'SEND_ALTERNATIVE_OPTIONS',
  SEND_BALANCE_REQUEST = 'SEND_BALANCE_REQUEST',
  SEND_CHECK_IN_INSTRUCTIONS = 'SEND_CHECK_IN_INSTRUCTIONS',
  SEND_MINIMUM_STAY_NOTE = 'SEND_MINIMUM_STAY_NOTE',
  SEND_PAYMENT_REMINDER = 'SEND_PAYMENT_REMINDER',
  SEND_PRE_ARRIVAL_FORM = 'SEND_PRE_ARRIVAL_FORM',
  SEND_QUOTE = 'SEND_QUOTE',
  SEND_REMINDER = 'SEND_REMINDER',
}

export enum LeadPipelineAction {
  ACCEPT_PENDING = 'ACCEPT_PENDING',
  CANCEL_BOOKING = 'CANCEL_BOOKING',
  CLOSE_LEAD = 'CLOSE_LEAD',
  DECLINE_PENDING = 'DECLINE_PENDING',
  EXTEND_HOLD = 'EXTEND_HOLD',
  IGNORE_LEAD = 'IGNORE_LEAD',
  MARK_AS_BOOKED = 'MARK_AS_BOOKED',
  PLACE_ON_HOLD = 'PLACE_ON_HOLD',
  PRE_APPROVE = 'PRE_APPROVE',
  REBOOK = 'REBOOK',
  REOPEN = 'REOPEN',
  REVOKE_HOLD = 'REVOKE_HOLD',
  SEND_QUOTE = 'SEND_QUOTE',
  SEND_CHECK_IN_INSTRUCTIONS = 'SEND_CHECK_IN_INSTRUCTIONS',
}

export enum LeadCreatorType {
  EMPLOYEE = 'EMPLOYEE',
  OWNER = 'OWNER',
}

export enum LeadBookingCancellationOrigin {
  HOST = 'HOST',
  GUEST = 'GUEST',
}

export interface Lead {
  uid: string;
  address?: string;
  address2?: string;
  assignee: LeadAssignee;
  availablePipelineActions?: LeadAvailablePipelineAction[];
  bookedDate?: Date;
  bookedDateTimeString?: string;
  cellPhoneNumber?: string;
  channel?: LeadChannelTO;
  city?: string;
  countdown?: LeadCountdown;
  countryCode?: string;
  creator?: LeadCreator;
  order?: Order;
  ownerCommission?: OwnerCommission;
  property: Partial<Property>;
  source: LeadSource;
  status: LeadStatus;
  checkInLocalDateTime: string;
  checkOutLocalDateTime: string;
  checkInDate?: Date;
  checkOutDate?: Date;
  arrivalLocalDateTime?: string;
  departureLocalDateTime?: string;
  arrivalDate?: Date;
  departureDate?: Date;
  adultCount: number;
  childrenCount: number;
  infantCount: number;
  petCount: number;
  phoneNumber?: string;
  preferredLocale?: LanguageCode;
  agreementSigned: boolean;
  autoRefundFail?: boolean;
  autoRefundOk?: boolean;
  hasCompletedPreArrivalForm: boolean;
  wereCheckInInstructionsSent: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  leadType?: string;
  createdDate?: Date;
  createdDateTimeString?: string;
  paidInFull?: boolean;
  referrer?: string;
  extraGuests?: {
    firstName: string;
    lastName: string;
    email: string;
    age: number;
  }[];
  willHaveParty?: boolean;
  willHaveVisit?: boolean;
  passportID?: string;
  passportCountryCode?: string;
  guest?: any;
  customData?: any;
  notes?: string;
  extraNotes?: string;
  flightNumber?: string;
  pinCode?: PinCodeLead;
  reviewByHost?: ReviewByHost;
  reasonForTrip?: string;
  secondaryEmail?: string;
  state?: string;
  zipCode?: string;
  rentalAgreementURL?: string;
  creationMetadata?: string;
  externalBookingID?: string;
  usePreAuthForDeposits?: boolean;
  wasProcessed?: boolean;
  wasSecurityDepositReceived?: boolean;
  wasSecurityDepositReturned?: boolean;
  riskScore?: number;
  fieldsPermissionsMetadata?: {
    fieldName: string;
    metadata: {
      editable: boolean;
    };
  }[];
  unitUid?: string;
  hotel?: {
    uid: string;
    name: string;
  };
  bookingDotComData?: {
    bookingDotComId?: string;
    bookingDotComRoomId?: string;
    rateId?: string;
    reservationId?: string;
    roomReservationId?: string;
  };
  checkInUtcDateTime?: string;
  checkOutUtcDateTime?: string;
  checkInUtcDate?: Date;
  checkOutUtcDate?: Date;
  duplicateOfLeadUid?: string;
  hotelUid?: string;
  groupUid?: string;
  isGroupBooking?: boolean;
  unit?: {
    uid: string;
    name: string;
  };
}

export interface LeadAssignee {
  uid: string;
  bestProfile: string;
  picture?: string;
}

export interface LeadChannelTO {
  bestProfile: string;
  channel: LeadChannel;
}

export interface LeadCountdown {
  color: LeadCircleColor;
  countdownMinutes?: number;
  countdownPercentage?: number;
  overdue?: boolean;
  visualCountdownValue?: string;
}

export interface LeadCreator {
  type: LeadCreatorType;
}

export interface GetAllLeadChannelsResponseTO extends BaseResponse {
  leadChannels: LeadChannelTO[];
}

export interface GetLeadsGraphQLResponseTO {
  data: {
    leads: Lead[];
  };
}

export interface LeadSavingPayload {
  address?: string;
  address2?: string;
  adultCount: number;
  arrivalLocalDateTime: string;
  assigneeUid?: string;
  attributeToVrbo?: boolean;
  cellPhoneNumber?: string;
  checkInDate: string;
  checkOutDate: string;
  childrenCount: number;
  city?: string;
  countryCode: string;
  departureLocalDateTime: string;
  email: string;
  extraNotes?: string;
  firstName?: string;
  flightNumber?: string;
  infantCount: number;
  lastName?: string;
  leadType: LeadType;
  notes?: string;
  overrides?: LeadQuoteOverride;
  petCount: number;
  phoneNumber?: string;
  preferredLocale: string;
  propertyUid: string;
  reasonForTrip?: string;
  rentalAgreementURL?: string;
  secondaryEmail?: string;
  source: LeadSource;
  state?: string;
  status: LeadStatus;
  zipCode?: string;
}

export interface LeadSavingResult {
  uid: string;
  propertyUid: string;
  checkInLocalDateTime: string;
  checkOutLocalDateTime: string;
  checkInDate?: Date;
  checkOutDate?: Date;
  checkInUtcDateTime?: string;
  checkOutUtcDateTime?: string;
  checkInUtcDate?: Date;
  checkOutUtcDate?: Date;
  source: LeadSource;
  status: LeadStatus;
  // define more properties that are coming from the backend if required (com.orbirental.api.v2.TOs.LeadApiTO)
}

export interface LeadGuestInformation {
  firstName: string;
  lastName: string;

  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  state: string;
  countryCode: string;

  passportId: string;
  passportCountryCode: string;

  adultCount: number;
  childrenCount: number;
  petCount: number;
  infantCount: number;
  riskScore: number;

  email: string;
  secondaryEmail: string;
  phoneNumber: string;
  cellPhoneNumber: string;

  flightNumber: string;
  reasonForTrip: string;
  willHaveParty: boolean;
  willHaveVisit: boolean;

  preferredCurrency: string;
  preferredLocale: string;

  arrivalLocalDateTime: string;
  departureLocalDateTime: string;
}

export interface LeadUpdateInternalPayload {
  propertyUid?: string;
  unitUid?: string;
  checkInLocalDateTime?: string;
  checkOutLocalDateTime?: string;
  checkInLocalDate?: string;
  checkOutLocalDate?: string;
  status: LeadStatus;
  assigneeUid?: string;
  attributeToVrbo?: boolean;
  guestInformation?: LeadGuestInformation;
  notes?: string;
  extraNotes?: string;
  quoteOverrides?: LeadQuoteOverride;
}

export interface LeadInternalResponse {
  lead?: {
    uid: string;
    propertyUid: string;
    unitUid: string;
    checkInLocalDateTime: string;
    checkOutLocalDateTime: string;
    checkInLocalDate: string;
    checkOutLocalDate: string;
    status: LeadStatus;
    type: LeadType;
    source: LeadSource;
    bookedUtcDateTime: string;
    externalBookingId: string;
    notes: string;
    extraNotes: string;
    assignee: {
      uid: string;
      type: LeadCreatorType;
    };
    metadata: {
      createdUtcDateTime: string;
      updatedUtcDateTime: string;
      creationCircumstances: string;
    };
    guestInformation: LeadGuestInformation;
  };
  apiErrorMessage?: string;
}
