import { CountryCode } from './Country';

export const CURRENCIES = [
  'AED',
  'ARS',
  'AUD',
  'BGN',
  'BRL',
  'CAD',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CZK',
  'DKK',
  'EGP',
  'EUR',
  'GBP',
  'GTQ',
  'HKD',
  'IDR',
  'ILS',
  'INR',
  'JPY',
  'KRW',
  'MAD',
  'MXN',
  'MYR',
  'NOK',
  'NONE',
  'NZD',
  'PEN',
  'PHP',
  'PLN',
  'RON',
  'RUB',
  'SAR',
  'SEK',
  'SGD',
  'THB',
  'TRY',
  'TTD',
  'TWD',
  'USD',
  'UYU',
  'VEF',
  'VND',
  'VUV',
  'JOD',
  'ZAR',
] as const;

export const currencyToFlagCode: {
  [key in CurrencyWithoutNone]: CountryCode | 'EU';
} = {
  AED: CountryCode.AE,
  ARS: CountryCode.AR,
  AUD: CountryCode.AU,
  BGN: CountryCode.BG,
  BRL: CountryCode.BR,
  CAD: CountryCode.CA,
  CHF: CountryCode.CH,
  CLP: CountryCode.CL,
  CNY: CountryCode.CN,
  COP: CountryCode.CO,
  CZK: CountryCode.CZ,
  DKK: CountryCode.DK,
  EGP: CountryCode.EG,
  EUR: 'EU',
  GBP: CountryCode.GB,
  GTQ: CountryCode.GT,
  HKD: CountryCode.HK,
  IDR: CountryCode.ID,
  ILS: CountryCode.IL,
  INR: CountryCode.IN,
  JPY: CountryCode.JP,
  KRW: CountryCode.KR,
  MAD: CountryCode.MA,
  MXN: CountryCode.MX,
  MYR: CountryCode.MY,
  NOK: CountryCode.NO,
  NZD: CountryCode.NZ,
  PEN: CountryCode.PE,
  PHP: CountryCode.PH,
  PLN: CountryCode.PL,
  RON: CountryCode.RO,
  RUB: CountryCode.RU,
  SAR: CountryCode.SA,
  SEK: CountryCode.SE,
  SGD: CountryCode.SG,
  THB: CountryCode.TH,
  TRY: CountryCode.TR,
  TTD: CountryCode.TT,
  TWD: CountryCode.TW,
  USD: CountryCode.US,
  UYU: CountryCode.UY,
  VEF: CountryCode.VE,
  VND: CountryCode.VN,
  VUV: CountryCode.VU,
  JOD: CountryCode.JO,
  ZAR: CountryCode.ZA,
};

export const CURRENCIES_WITHOUT_NONE = CURRENCIES.filter(
  (currency) => currency !== 'NONE',
);

export type Currency = (typeof CURRENCIES)[number];

export type CurrencyWithoutNone = Exclude<Currency, 'NONE'>;
