import { Row } from '@tanstack/react-table';
import Button from 'components/button/Button';
import { PropertyInternalFee } from 'models/PropertyFees';
import { useTranslation } from 'react-i18next';
import PropertyEditActionWrapper from 'pages/property/common/PropertyEditActionWrapper';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import useOpenPropertyFeeModal from '../modal/useOpenPropertyFeeModal';
import useDeleteModal from '../modal/useDeleteModal';

const ActionsCell = ({ row }: { row: Row<PropertyInternalFee> }) => {
  const { t } = useTranslation();
  const { property, isLoading } = usePropertySettingsInitData();
  const { openBulkDeleteModal } = useDeleteModal({ row, property });
  const { openEditModal } = useOpenPropertyFeeModal();

  return (
    <>
      <PropertyEditActionWrapper tooltipId="row-edit-fee-button-tooltip">
        <Button
          bsStyle="link"
          data-testid="row-edit-fee-button"
          onClick={() => openEditModal(row.original)}
        >
          {t('common.edit')}
        </Button>
      </PropertyEditActionWrapper>
      <PropertyEditActionWrapper tooltipId="row-delete-fee-button-tooltip">
        <Button
          bsStyle="link"
          data-testid="row-delete-fee-button"
          onClick={openBulkDeleteModal}
          disabled={isLoading}
        >
          {t('common.delete')}
        </Button>
      </PropertyEditActionWrapper>
    </>
  );
};

export default ActionsCell;
