import { Currency } from './Currency';

export enum UserType {
  Agent = 'Agent',
  Employee = 'Employee',
  Guest = 'Guest',
  Owner = 'Owner',
  ServiceProviderUser = 'ServiceProviderUser',
}

export interface Agency {
  uid: string;
  name: string;
  currencySymbol: string;
  currency: Currency;
  email?: string;
  phone?: string;
  pricingPlanType?: string;
  website?: string;
  timeZone?: string;
  usePermissionedPMS: boolean;
  zipCode?: string;
}

export interface AppUser {
  agency: Agency;
  agencyUid: string;
  employeeRole?: UserEmployeeRole;
  isAgent: boolean;
  isEmployee: boolean;
  isEmployeeAssociate: boolean;
  isEmployeeBookingAgent: boolean;
  isEmployeeManager: boolean;
  isEmployeePropertySalesManager: boolean;
  isGuest: boolean;
  isOwner: boolean;
  isServiceProvider: boolean;
  user: User;
  userType: UserType;
  userUid: string;
}

export interface User {
  uid: string;
  firstName?: string;
  lastName?: string;
  email: string;
  intercomId?: string;
  pictureUrl?: string;
  type: UserType;
  agency: Agency;
  bestProfile?: string;
  created: string;
  preferredLocale?: string;
  employeeRole?: UserEmployeeRole;
}

export enum UserEmployeeRole {
  MANAGER = 'MANAGER',
  BOOKING_AGENT = 'BOOKING_AGENT',
  PROPERTY_SALES_MANAGER = 'PROPERTY_SALES_MANAGER',
  ASSOCIATE = 'ASSOCIATE',
}
