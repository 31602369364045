import TextField from 'components/forms/textField/TextField';
import { Lead } from 'models/Leads';
import { useTranslation } from 'react-i18next';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { isLeadASubBooking } from 'utils/lead/leadUtils';
import {
  CustomDataColumn,
  CustomDataPageHeader,
  CustomDataWrapper,
  LeadDataFormRow,
} from './LeadModalDataTab.styles';

const BookingComData = ({ lead }: { lead: Lead }) => {
  const { t } = useTranslation();
  const { getCopyAddonIcon } = useCopyToClipboard();
  const isSubBooking = isLeadASubBooking(lead);

  return (
    <CustomDataWrapper data-testid="custom-data-wrapper-bookingCom">
      <CustomDataColumn md={12}>
        <CustomDataPageHeader
          title={`${t(
            'componentLead.modal.dataTab.customDataBookingComTitle',
          )}`}
        />
      </CustomDataColumn>

      <CustomDataColumn md={10}>
        <LeadDataFormRow>
          <TextField
            disabled
            label={t('componentLead.modal.dataTab.reservationID')}
            name="bookingDotComData.reservationId"
            value={lead?.bookingDotComData?.reservationId}
            rightAddons={[
              getCopyAddonIcon(lead?.bookingDotComData?.reservationId),
            ]}
            colSmInput={5}
            colSmLabel={3}
          />
        </LeadDataFormRow>
      </CustomDataColumn>

      {lead?.bookingDotComData && lead?.bookingDotComData?.bookingDotComId && (
        <CustomDataColumn md={10}>
          <LeadDataFormRow>
            <TextField
              disabled
              label={t(
                'componentLead.modal.dataTab.bookingDotComData.bookingDotComHotelId',
              )}
              value={lead?.bookingDotComData?.bookingDotComId}
              name="bookingDotComData.bookingDotComId"
              rightAddons={[
                getCopyAddonIcon(lead?.bookingDotComData?.bookingDotComId),
              ]}
              colSmInput={5}
              colSmLabel={3}
            />
          </LeadDataFormRow>
        </CustomDataColumn>
      )}
      {lead?.bookingDotComData &&
        lead?.bookingDotComData?.bookingDotComRoomId && (
          <CustomDataColumn md={10}>
            <LeadDataFormRow>
              <TextField
                disabled
                label={t(
                  'componentLead.modal.dataTab.bookingDotComData.bookingDotComRoomId',
                )}
                value={lead?.bookingDotComData?.bookingDotComRoomId}
                name="bookingDotComData.bookingDotComRoomId"
                rightAddons={[
                  getCopyAddonIcon(
                    lead?.bookingDotComData?.bookingDotComRoomId,
                  ),
                ]}
                colSmInput={5}
                colSmLabel={3}
              />
            </LeadDataFormRow>
          </CustomDataColumn>
        )}

      {isSubBooking &&
        lead?.bookingDotComData &&
        lead?.bookingDotComData?.roomReservationId && (
          <CustomDataColumn md={10}>
            <LeadDataFormRow>
              <TextField
                disabled
                label={t(
                  'componentLead.modal.dataTab.bookingDotComData.roomReservationId',
                )}
                value={lead?.bookingDotComData?.roomReservationId}
                name="bookingDotComData.roomReservationId"
                rightAddons={[
                  getCopyAddonIcon(lead?.bookingDotComData?.roomReservationId),
                ]}
                colSmInput={5}
                colSmLabel={3}
              />
            </LeadDataFormRow>
          </CustomDataColumn>
        )}
      {lead?.bookingDotComData && lead?.bookingDotComData?.rateId && (
        <CustomDataColumn md={10}>
          <LeadDataFormRow>
            <TextField
              disabled
              value={lead?.bookingDotComData?.rateId}
              label={t('componentLead.modal.dataTab.bookingDotComData.rateId')}
              name="bookingDotComData.rateId"
              rightAddons={[getCopyAddonIcon(lead?.bookingDotComData?.rateId)]}
              colSmInput={5}
              colSmLabel={3}
            />
          </LeadDataFormRow>
        </CustomDataColumn>
      )}
    </CustomDataWrapper>
  );
};

export default BookingComData;
