import { ReactNode } from 'react';
import { BreadcrumbItem, Glyphicon } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import FormWithProvider from 'components/forms/form/Form';
import SidePanel from 'components/sidePanel/SidePanel';
import useSidePanel from 'components/sidePanel/useSidePanel';
import { Property, PropertySubUnit, PropertyUnitType } from 'models/Properties';
import { normalizeKey } from 'utils/localeUtils';
import {
  PropertyPageBreadcrumb,
  PropertySelectBreadcrumbItemStyled,
} from './common/Common.styles';
import PropertySettingsTabHeaderPropertySelector from './PropertySettingsTabHeaderPropertySelector';
import {
  MenuButtonStyled,
  PropertySettingsTabHeaderContainer,
} from './PropertySettingsTabHeader.styles';
import PropertySettingsTabHotelBreadcrumbItem from './PropertySettingsTabHotelBreadcrumbItem';
import {
  PropertySettingsTab,
  settingsTabSidePanelId,
} from './PropertySettingsTabs.constants';
import PropertySettingsMainTabHeader from './mainSettings/PropertySettingsMainTabHeader';
import PropertySettingsDescriptionsTabHeader from './descriptions/PropertySettingsDescriptionsTabHeader';
import PropertySettingsPricingTabHeader from './pricing/PropertySettingsPricingTabHeader';

const PropertySettingsTabHeader = ({
  activeTab,
  hamburgerMenuContent,
  property,
}: {
  activeTab: string;
  hamburgerMenuContent?: ReactNode;
  property: Property | PropertySubUnit | PropertyUnitType;
}) => {
  const { uid: selectedPropertyUid } = property;

  const { t } = useTranslation();
  const { triggerClassName, toggleSidePanel } = useSidePanel({
    sidePanelId: settingsTabSidePanelId,
  });

  const tabHeaderButtonsComponentMap = {
    [PropertySettingsTab.descriptions]: PropertySettingsDescriptionsTabHeader,
    [PropertySettingsTab.mainSettings]: PropertySettingsMainTabHeader,
    [PropertySettingsTab.pricing]: PropertySettingsPricingTabHeader,
  };

  const DynamicTabHeaderButtons =
    tabHeaderButtonsComponentMap[activeTab] || (() => null);

  return (
    <PropertySettingsTabHeaderContainer>
      {hamburgerMenuContent && (
        <>
          <MenuButtonStyled
            data-testid="settings-tab-hamburger-menu"
            onClick={toggleSidePanel}
            className={triggerClassName}
            bsStyle="default"
          >
            <Glyphicon glyph="menu-hamburger" />
          </MenuButtonStyled>
          <SidePanel sidePanelId={settingsTabSidePanelId}>
            {hamburgerMenuContent}
          </SidePanel>
        </>
      )}
      <FormWithProvider onSubmit={() => {}} defaultValues={{}}>
        <PropertyPageBreadcrumb>
          <BreadcrumbItem>
            <NavLink to="/properties">
              {t('common.pageTitles.properties')}
            </NavLink>
          </BreadcrumbItem>
          <PropertySettingsTabHotelBreadcrumbItem
            activeTab={activeTab}
            property={property}
          />
          <PropertySelectBreadcrumbItemStyled active>
            <PropertySettingsTabHeaderPropertySelector
              activeTab={activeTab}
              currentPropertyUid={selectedPropertyUid}
            />
          </PropertySelectBreadcrumbItemStyled>
          <BreadcrumbItem active>
            {t(normalizeKey(`pageProperty.tabNames.${activeTab}`))}
          </BreadcrumbItem>
        </PropertyPageBreadcrumb>
      </FormWithProvider>

      <DynamicTabHeaderButtons />
    </PropertySettingsTabHeaderContainer>
  );
};

PropertySettingsTabHeader.defaultProps = {
  hamburgerMenuContent: undefined,
};

export default PropertySettingsTabHeader;
