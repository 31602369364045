import * as Yup from 'yup';
import { DeepPartial } from 'react-hook-form';
import { CURRENCIES_WITHOUT_NONE, Currency } from 'models/Currency';

export const propertySettingsPricingTabSchema = () => {
  return Yup.object({
    priceSettings: Yup.object({
      baseDailyRate: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .positive()
        .when('currency', { is: 'USD', then: Yup.number().min(10) })
        .required(),
      currency: Yup.mixed<Currency>().oneOf(CURRENCIES_WITHOUT_NONE).required(),
      securityDepositAmount: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .optional()
        .nullable(),
      useMinimumPriceRule: Yup.mixed<boolean | string>()
        .transform((value) => value === 'true')
        .optional()
        .nullable(),
      weekEndRatePercentAdjustment: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .max(100)
        .optional()
        .nullable(),
    }),
  });
};

export type PropertySettingsPricingTabFormValues = DeepPartial<
  Yup.InferType<ReturnType<typeof propertySettingsPricingTabSchema>>
>;

export function getFormDefaultValues(): PropertySettingsPricingTabFormValues {
  return {
    priceSettings: {
      baseDailyRate: 0,
      currency: 'USD',
      securityDepositAmount: 0,
      useMinimumPriceRule: 'false',
      weekEndRatePercentAdjustment: 0,
    },
  };
}
