import { useIsMutating } from '@tanstack/react-query';
import Button from 'components/button/Button';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import FormWithProvider from 'components/forms/form/Form';
import useAppModal from 'hooks/useAppModal';
import {
  FeeAmountTypeEnum,
  FeeConditionEnum,
  FeeScopeEnum,
  FeeTypeEnum,
  HostfullyFeeType,
  PropertyInternalFee,
} from 'models/PropertyFees';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Currency } from 'models/Currency';
import { Property } from 'models/Properties';
import PropertyFeeModalFields from './PropertyFeeModalFields';
import {
  PropertyFeeFormSchemaType,
  propertyFeeSchema,
} from './PropertyFeeModal.schema';
import PropertyFeeModalPlaceholder from './PropertyFeeModalPlaceholder';
import { BY_LENGTH_OF_STAY_SCOPE_OPTION } from './useFieldsOptions';

export const ADD_PROPERTY_FEE_MODAL_ID = 'add-property-fee-modal';
export const EDIT_PROPERTY_FEE_MODAL_ID = 'edit-property-fee-modal';

const PropertyFeeModal = ({
  fee,
  handleSubmit,
  queryKey,
  propertyCurrency,
  property,
  isLoadingProperty,
}: {
  fee?: PropertyInternalFee;
  handleSubmit: (data: PropertyFeeFormSchemaType) => void;
  queryKey: string[];
  propertyCurrency: Currency;
  property: Property;
  isLoadingProperty: boolean;
}) => {
  const { closeModal } = useAppModal();
  const { t } = useTranslation();
  const isMutating = useIsMutating(queryKey);

  const isEdit = !!fee;
  const isTax = fee?.type === FeeTypeEnum.TAX;
  const isFee = fee?.type === FeeTypeEnum.CUSTOM;
  const isSubmitting = isMutating > 0;

  const handleCancel = () => {
    const modalId = isEdit
      ? EDIT_PROPERTY_FEE_MODAL_ID
      : ADD_PROPERTY_FEE_MODAL_ID;

    closeModal(modalId);
  };

  const getFormDefaultValues = () => {
    const addInitialValues = {
      appliesTo: PROPERTIES_SELECTION.SELECTED_PROPERTIES,
      appliesToProperties: [
        {
          label: property.name,
          value: property.uid,
          isFixed: true,
        },
      ],
      appliesToHostfully: 'NO',
      optional: false,
      type: FeeTypeEnum.CUSTOM,
      fee: {
        type: FeeAmountTypeEnum.AMOUNT,
        value: '',
        taxationRate: 0,
      },
      tax: {
        conditions: {
          type: FeeConditionEnum.NONE,
          lengthOfStayType: FeeConditionEnum.EXEMPT_LONG_STAY,
          lengthOfStayValue: null,
        },
        value: '',
        type: FeeAmountTypeEnum.AMOUNT,
      },
      scope: {
        amount: FeeScopeEnum.PER_STAY,
        tax: FeeScopeEnum.PERCENTAGE_OF_RENT,
      },
    };

    const getEditInitialValues = () => {
      const getAppliesToProperties = () => {
        if (fee?.metadata?.appliesToAllProperties) {
          return [];
        }
        const properties =
          fee?.metadata?.propertiesThatShareFee?.map(
            ({ left: uid, right: name }) => ({
              label: name,
              value: uid,
              isFixed: uid === property?.uid,
            }),
          ) ?? [];

        // Adding because the current property is not included in the propertiesThatShareFee
        return [
          {
            label: property?.name,
            value: property?.uid,
            isFixed: true,
          },
          ...properties,
        ];
      };

      return {
        uid: fee?.uid,
        airbnbMappingType: fee?.airbnbType,
        airbnbUnitMappingType: fee?.airbnbUnitType,
        type: fee?.type,
        name: fee?.name,
        optional: fee?.optional,
        appliesToHostfully:
          fee?.hostfullyType === HostfullyFeeType.DEFAULT ? 'YES' : 'NO',
        appliesTo: fee?.metadata?.appliesToAllProperties
          ? PROPERTIES_SELECTION.ALL_PROPERTIES
          : PROPERTIES_SELECTION.SELECTED_PROPERTIES,
        appliesToProperties: getAppliesToProperties(),
        scope:
          fee?.amountType === FeeAmountTypeEnum.TAX
            ? { tax: fee?.scope, amount: FeeScopeEnum.PER_STAY }
            : { amount: fee?.scope, tax: FeeScopeEnum.PERCENTAGE_OF_RENT },
        vrboMappingType: fee?.vrboType,
        hvmbMappingType: fee?.hvmiType,
        bookingMappingType: fee?.bookingDotComType,
        fee: isFee
          ? {
              type: fee?.amountType,
              value: fee?.amount,
              taxationRate: fee?.taxationRate,
            }
          : addInitialValues.fee,
        tax: isTax
          ? {
              type: fee?.amountType,
              value: fee?.amount,
              conditions:
                fee?.conditions === FeeConditionEnum.NONE
                  ? {
                      type: fee?.conditions,
                      lengthOfStayType: null,
                      lengthOfStayValue: null,
                    }
                  : {
                      type: BY_LENGTH_OF_STAY_SCOPE_OPTION,
                      lengthOfStayType: fee?.conditions,
                      lengthOfStayValue: fee.conditionsLOSDays,
                    },
            }
          : addInitialValues.tax,
      };
    };

    return isEdit ? getEditInitialValues() : addInitialValues;
  };

  if (isLoadingProperty) {
    return <PropertyFeeModalPlaceholder isEdit={isEdit} />;
  }

  return (
    <div>
      <FormWithProvider
        horizontal
        schema={propertyFeeSchema()}
        onSubmit={handleSubmit}
        defaultValues={getFormDefaultValues()}
        noValidate
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEdit
              ? t(
                  'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.editFeeModalTitle',
                )
              : t(
                  'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.addFeeModalTitle',
                )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PropertyFeeModalFields
            propertyCurrency={propertyCurrency}
            propertyUid={property?.uid}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCancel}>{t('common.cancel')}</Button>
          <Button type="submit" bsStyle="primary" disabled={isSubmitting}>
            {t('common.save')}
          </Button>
        </Modal.Footer>
      </FormWithProvider>
    </div>
  );
};

PropertyFeeModal.defaultProps = {
  fee: undefined,
};

export default PropertyFeeModal;
