import { addDays, format } from 'date-fns';
import { LeadModeType } from 'models/DashboardLeadModes';
import { getUTCDate } from 'utils/dateTimeUtils';
import useFetchLeadsPaginated from '../../../components/domain/lead/useFetchLeadsPaginated';

const getDashboardLeadsParams = (
  type: LeadModeType,
  hideProcessedLeads = false,
) => {
  const checkinCheckoutPeriodOfTime = 14;
  const currentDate = new Date();
  const utcDate = getUTCDate(currentDate);
  const inFourteenDaysUtcDate = addDays(utcDate, checkinCheckoutPeriodOfTime);
  const currentUtcDateTime = format(utcDate, "yyyy-MM-dd'T'HH:mm:ss");
  const inFourteenDaysUtcDateTime = format(
    inFourteenDaysUtcDate,
    "yyyy-MM-dd'T'HH:mm:ss",
  );

  const shouldHideProcesedLeads =
    type === LeadModeType.NEW && hideProcessedLeads;

  const additionalFiltersParams = {
    new: '',
    checkin: `
      checkin_from_utc_datetime: "${currentUtcDateTime}"
      checkin_to_utc_datetime: "${inFourteenDaysUtcDateTime}"
    `,
    checkout: `
      checkout_from_utc_datetime: "${currentUtcDateTime}"
      checkout_to_utc_datetime: "${inFourteenDaysUtcDateTime}"
  `,
  };

  const sortStrategy = {
    new: 'CREATION_DATE_DESC',
    checkin: 'CHECK_IN_DATE_ASC',
    checkout: 'CHECK_OUT_DATE_ASC',
  };

  const statusStrategy = {
    new: '[NEW, QUOTE_SENT, PENDING, DUPLICATE, ON_HOLD, BOOKED_EXTERNALLY, BOOKED_BY_AGENT, BOOKED_BY_CUSTOMER, BOOKED_BY_OWNER, STAY, ARCHIVED, PAID_IN_FULL, SAMPLE]',
    checkin:
      '[BOOKED_EXTERNALLY, BOOKED_BY_AGENT, BOOKED_BY_CUSTOMER, BOOKED_BY_OWNER, PAID_IN_FULL, STAY]',
    checkout:
      '[BOOKED_EXTERNALLY, BOOKED_BY_AGENT, BOOKED_BY_CUSTOMER, BOOKED_BY_OWNER, PAID_IN_FULL, STAY]',
  };

  return {
    conditions: `${additionalFiltersParams[type]}
      ${shouldHideProcesedLeads ? 'hideProcessed: true' : ''}
      sortStrategy:"${sortStrategy[type]}",
      hideSubLeads:true,
      statuses:${statusStrategy[type]}`,
    fields: `assignee{bestProfile, uid, picture},
      channel{bestProfile, channel},
      uid,
      firstName,
      lastName, 
      email, 
      source,
      leadType,
      createdDateTimeString,
      checkInLocalDateTime, 
      checkOutLocalDateTime, 
      checkInUtcDateTime,
      checkOutUtcDateTime,
      childrenCount, 
      adultCount, 
      petCount, 
      infantCount, 
      status,
      referrer,
      hasCompletedPreArrivalForm,
      agreementSigned,
      paidInFull,
      wasProcessed,
      property{uid, name, businessType, mainPicture {
        largeThumbnail
      }},
      riskScore,
      creator{type},
      order {
        totalAmount,
        currency,
        currencySymbol,
      },
      hotel { 
        name,
        uid 
      },
      duplicateOfLeadUid,
      hotelUid,
      groupUid,
      isGroupBooking
     `,
  };
};

const useDashboardLeads = ({
  hideProcessedLeads,
  type,
}: {
  hideProcessedLeads: boolean;
  type: LeadModeType;
}) => {
  const { conditions, fields } = getDashboardLeadsParams(
    type,
    hideProcessedLeads,
  );
  const {
    leads,
    error,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isInitialFetching,
    refetch,
  } = useFetchLeadsPaginated({
    conditions,
    fields,
  });

  const isError = error === 'error';

  return {
    leads,
    isError,
    refetch,
    hasMoreLeads: hasNextPage && leads?.length >= 30,
    loadMoreLeads: fetchNextPage,
    isLoading: isFetching,
    isFirstLoading: isInitialFetching,
  };
};

export default useDashboardLeads;
