import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import TextField from 'components/forms/textField/TextField';
import TagsSelectField from 'components/forms/domain/tag/TagsSelectField';
import SelectField from 'components/forms/selectField/SelectField';
import SwitchField from 'components/forms/switchField/SwitchField';
import {
  ChannelSettingsSeparator,
  PhotoEditModalContainer,
  SwitchFieldContainer,
  TagsContainer,
} from './PhotoEditModal.styles';

interface PhotoEditModalFieldsProps {
  isAirbnbActive: boolean;
  airbnbRoomOptions: { label: string; value: string | null }[];
}

const PhotoEditModalFields = ({
  isAirbnbActive,
  airbnbRoomOptions,
}: PhotoEditModalFieldsProps) => {
  const { t } = useTranslation();
  const mapToRoomValue = useWatch({ name: 'mapToRoom' });

  return (
    <PhotoEditModalContainer>
      <TextField
        label={t('pageProperty.photos.editModal.caption')}
        name="caption"
        colSmInput={10}
        colSmLabel={2}
        componentClass="textarea"
        rows={4}
        maxLength={250}
        maxCharactersDescription={t(
          'pageProperty.photos.editModal.captionTooltip',
        )}
      />

      <TagsContainer>
        <Col xs={4} sm={2} className="tags-label">
          {t('pageProperty.photos.editModal.tags')}
        </Col>

        <Col xs={8} sm={10} className="tags-input">
          <TagsSelectField name="tags" options={[]} colXsInput={10} />
        </Col>
      </TagsContainer>

      {isAirbnbActive && (
        <>
          <ChannelSettingsSeparator>
            {t('pageProperty.photos.editModal.channelSpecific')}
          </ChannelSettingsSeparator>

          <SwitchFieldContainer>
            <Col xs={4} sm={2} className="map-to-room-label">
              {t('pageProperty.photos.editModal.mapToRoom')}
            </Col>

            <Col xs={2} sm={1}>
              <SwitchField name="mapToRoom" className="switch-field" />
            </Col>

            <Col xs={6} sm={5} className="airbnbRoomId-field">
              {mapToRoomValue && (
                <SelectField name="airbnbRoomId" options={airbnbRoomOptions} />
              )}
            </Col>
          </SwitchFieldContainer>
        </>
      )}
    </PhotoEditModalContainer>
  );
};

export default PhotoEditModalFields;
