import { useTranslation } from 'react-i18next';
import { PropertyBusinessType } from 'models/Properties';
import PageHeader from 'components/pageHeader/PageHeader';
import useFetchPhotos from 'components/domain/property/useFetchPhotos';
import PropertyEditPermissionInfo from '../common/PropertyEditPermissionInfo';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import PhotoGrid from './components/photoGrid/PhotoGrid';
import UploadPhotoButton from './components/upload/UploadPhotoButton';
import PhotoGridLoader from './components/photoGrid/PhotoGridLoader';

const PropertySettingsPhotosTab = () => {
  const { t } = useTranslation();
  const { isLoading: isLoadingInitData, property } =
    usePropertySettingsInitData();
  const { data: photos, isLoading: isLoadingPhotos } = useFetchPhotos({
    propertyUid: property.uid,
    isHotel: property.businessType === PropertyBusinessType.HOTEL,
    select: (data) => data.toSorted((a, b) => a.displayOrder - b.displayOrder),
  });

  const pageTitle = `${t('pageProperty.photos.tabTitle')}${
    photos?.length > 0 ? ` (${photos.length})` : ''
  }`;

  return (
    <div>
      <PropertyEditPermissionInfo />

      <PageHeader
        className="tab-title"
        title={pageTitle}
        isLoading={isLoadingInitData || isLoadingPhotos}
        buttons={<UploadPhotoButton />}
        subtitle={t('pageProperty.photos.subtitle')}
        noDivider
      />

      {isLoadingPhotos ? <PhotoGridLoader /> : <PhotoGrid photos={photos} />}
    </div>
  );
};

export default PropertySettingsPhotosTab;
