import { useTranslation } from 'react-i18next';
import PhotoIcon from 'assets/images/icons/photo.svg?react';
import { AddPhotoTileContainer } from './UploadPhoto.styles';
import UploadPhotoWrapper from './UploadPhotoWrapper';

const UploadPhotoTile = () => {
  const { t } = useTranslation();

  return (
    <UploadPhotoWrapper id="upload-photo-tile">
      <AddPhotoTileContainer>
        <PhotoIcon />
        <span>{t('pageProperty.photos.addPhoto')}</span>
      </AddPhotoTileContainer>
    </UploadPhotoWrapper>
  );
};

export default UploadPhotoTile;
