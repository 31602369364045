import { useContext, useEffect } from 'react';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';

import {
  FeeAmountType,
  FeeScopeType,
  QuoteOverrideFeeType,
  QuoteOverrides,
  QuoteResponse,
} from 'models/Quote';
import { useFormContext, useWatch } from 'react-hook-form';
import useAppUser from 'hooks/useAppUser';
import { getUnitType, isLeadAMainBooking } from 'utils/lead/leadUtils';
import { format } from 'date-fns';
import { HasOrderItems } from 'models/hasOrderItems/HasOrderItems';
import { prepareOrderItems } from 'utils/quote/quoteUtils';
import LeadModalContext from './LeadModalContext';
import useWatchQuoteGroupBookingData from './useWatchQuoteGroupBookingData';

const isQuoteNeedRefresh = (dirtyFields) => {
  if (dirtyFields?.quote) return true;
  if (dirtyFields?.propertyUid) return true;
  if (dirtyFields?.checkInDate) return true;
  if (dirtyFields?.checkOutDate) return true;
  if (dirtyFields?.adultCount) return true;
  if (dirtyFields?.childrenCount) return true;
  if (dirtyFields?.force100PercentPaymentAtReservation) return true;
  return false;
};

const getQuoteOverridesValuesBasedOnOrder = (
  order: HasOrderItems,
  dirtyFields,
): QuoteOverrides => {
  if (!order) return null;

  if (dirtyFields && Object.keys(dirtyFields).length === 0) {
    return null;
  }

  // If updating propertyUid we want to reset the whole quote
  if (dirtyFields?.propertyUid) {
    return null;
  }

  // If updating guests or dates, we reset the rent and property fees
  const resetRentAndPropertyFeesAmount =
    dirtyFields?.checkInDate ||
    dirtyFields?.checkOutDate ||
    dirtyFields?.adultCount ||
    dirtyFields?.childrenCount;

  const rentIsUpdated = dirtyFields?.quote?.rent?.rentBaseNetPrice;

  const overrides: QuoteOverrides = {
    rent: !resetRentAndPropertyFeesAmount
      ? {
          rentBaseNetPrice:
            order.rent?.rentBaseNetPrice > 0 ? order.rent?.rentBaseNetPrice : 0,
          extraGuestsBaseNetPrice:
            order.rent?.extraGuestsBaseNetPrice > 0
              ? order.rent?.extraGuestsBaseNetPrice
              : 0,
          taxationRate: dirtyFields?.quote?.rent?.taxAmount
            ? null
            : order?.rent?.taxationRate,
          taxAmount:
            dirtyFields?.quote?.rent?.taxationRate || rentIsUpdated
              ? null
              : order?.rent?.taxAmount,
        }
      : {
          rentBaseNetPrice: null,
          extraGuestsBaseNetPrice: null,
          taxationRate: order?.rent?.taxationRate,
          taxAmount: null,
        },
    securityDeposit: order.securityDeposit > 0 ? order.securityDeposit : 0,
    totalPrice: order.totalAmount > 0 ? order.totalAmount : 0,
    force100PercentPaymentAtReservation:
      order.force100PercentPaymentAtReservation,
    fees: {
      cleaningFee: {
        ...order?.fees?.cleaningFee,
        netPrice:
          order?.fees?.cleaningFee?.netPrice > 0
            ? order?.fees?.cleaningFee?.netPrice
            : 0,
      },
      otherFees: order?.fees?.otherFees.map((fee) => ({
        name: fee.name,
        taxationRate: fee.taxationRate,
        netPrice:
          (resetRentAndPropertyFeesAmount || rentIsUpdated) && fee.feeUid
            ? null
            : fee.netPrice,
        amount: null,
        amountType: FeeAmountType.AMOUNT,
        scope: FeeScopeType.PER_STAY,
        type: QuoteOverrideFeeType.CUSTOM,
        isOptional: fee.isOptional,
        enabled: fee.enabled,
        feeId: fee.feeId,
        feeUid: fee.feeUid,
        removed: fee.removed,
      })),
    },
  };

  return overrides;
};

const useWatchQuoteData = () => {
  const {
    availableProperties,
    setOverrides,
    setPropertyFees,
    lead,
    setQuoteAdjustments,
  } = useContext(LeadModalContext);
  const { agencyUid } = useAppUser();
  const {
    reset,
    formState: { isDirty, dirtyFields },
    getValues,
  } = useFormContext();
  const {
    quote,
    uid: leadUid,
    checkInDate,
    checkOutDate,
    adultCount,
    childrenCount,
    propertyUid,
  } = useWatch();

  const overridesAdjusted = getQuoteOverridesValuesBasedOnOrder(
    quote,
    dirtyFields,
  );

  const { groupBooking, isGroupBookingLoading } = useWatchQuoteGroupBookingData(
    { lead },
  );

  const { data: newQuote, isFetching: isNewQuoteLoading } = useAppQuery(
    [
      `get-quote-${leadUid}`,
      overridesAdjusted,
      leadUid,
      checkInDate,
      checkOutDate,
      adultCount,
      childrenCount,
      propertyUid,
    ],
    async () => {
      const unitType = getUnitType(availableProperties, propertyUid);
      const response = await API.post<QuoteResponse>('/api/v3/quotes', {
        leadUid: leadUid || undefined,
        overrides: overridesAdjusted,
        agencyUid,
        propertyUid: unitType?.uid || propertyUid,
        checkInDate: format(checkInDate, 'yyyy-MM-dd'),
        checkOutDate: format(checkOutDate, 'yyyy-MM-dd'),
        guests: parseInt(adultCount, 10) + parseInt(childrenCount, 10),
      });
      return response.data.quote;
    },
    {
      enabled:
        !!agencyUid &&
        !!propertyUid &&
        ((isDirty && isQuoteNeedRefresh(dirtyFields)) || !quote) &&
        !isLeadAMainBooking(lead),
    },
  );

  useEffect(() => {
    if (newQuote) {
      const newOrder = prepareOrderItems(newQuote, lead);
      const overrides = getQuoteOverridesValuesBasedOnOrder(quote, dirtyFields);

      setOverrides({ ...overrides });
      setPropertyFees(
        newQuote.overrides.fees.otherFees.filter((fee) => fee.feeUid !== null),
      );

      setQuoteAdjustments(newQuote.adjustments);

      reset(
        {
          ...getValues(),
          propertyUid,
          checkInDate,
          checkOutDate,
          adultCount,
          childrenCount,
          quote: newOrder,
        },
        {
          keepDirty: false,
        },
      );
    }
  }, [newQuote]);

  return {
    newQuote,
    isNewQuoteLoading,
    groupBooking,
    isGroupBookingLoading,
  };
};

export default useWatchQuoteData;
