import { ModalBody, Row } from 'react-bootstrap';
import FormWithProvider from 'components/forms/form/Form';
import { Property } from 'models/Properties';
import PriceRulesAddEditModalFooter from './PriceRulesAddEditModalFooter';
import {
  getFormDefaultValues,
  priceRulesAddEditFormSchema,
} from './PriceRulesAddEditModal.schema';
import PriceRulesAddEditModalNameField from './PriceRulesAddEditModalNameField';
import PriceRulesAddEditModalTypeField from './PriceRulesAddEditModalTypeField';
import PriceRulesAddEditModalChannelField from './PriceRulesAddEditModalChannelField';
import PriceRulesAddEditModalAdjustPriceField from './PriceRulesAddEditModalAdjustPriceField';
import PriceRulesAddEditModalThreshold from './PriceRulesAddEditModalThreshold';
import PriceRulesAddEditModalAppliesToField from './PriceRulesAddEditModalAppliesToField';

interface PriceRulesAddEditModalProps {
  modalId: string;
  property: Property;
}

const PriceRulesAddEditModal = ({
  modalId,
  property,
}: PriceRulesAddEditModalProps) => {
  return (
    <FormWithProvider
      defaultValues={getFormDefaultValues({ property })}
      disabled={false}
      onSubmit={() => {}}
      schema={priceRulesAddEditFormSchema()}
      noValidate
    >
      <fieldset disabled={false}>
        <ModalBody>
          <Row className="d-flex flex-column">
            <PriceRulesAddEditModalNameField />
            <PriceRulesAddEditModalTypeField />
            <PriceRulesAddEditModalChannelField />
            <PriceRulesAddEditModalAdjustPriceField />
            <PriceRulesAddEditModalThreshold />
            <PriceRulesAddEditModalAppliesToField />
          </Row>
        </ModalBody>
        <PriceRulesAddEditModalFooter modalId={modalId} />
      </fieldset>
    </FormWithProvider>
  );
};

export default PriceRulesAddEditModal;
