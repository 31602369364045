import TextField from 'components/forms/textField/TextField';
import { useTranslation } from 'react-i18next';

const PriceRulesAddEditModalThreshold = () => {
  const { t } = useTranslation();

  return (
    <TextField
      label={t('pageProperty.pricing.priceRules.modal.form.threshold')}
      name="priceRule.threshold"
      colSmInput={8}
      colSmLabel={4}
      step="any"
      type="number"
      rightAddons={[
        t('pageProperty.pricing.priceRules.modal.form.thresholdRightAddon'),
      ]}
      required
    />
  );
};

export default PriceRulesAddEditModalThreshold;
