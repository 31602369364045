import { FeatureFlag } from 'models/Config';
import useAppConfig from './useAppConfig';

export interface AllowedFlags {
  allowPropertiesWebapp: boolean;
  allowOrderViewWebapp: boolean;
}

const useFeatures = (): AllowedFlags => {
  const { featureFlags } = useAppConfig();
  return {
    allowPropertiesWebapp: !featureFlags.includes(
      FeatureFlag.ALLOW_PROPERTIES_WEBAPP,
    ),
    allowOrderViewWebapp: !featureFlags.includes(
      FeatureFlag.ALLOW_ORDER_VIEW_WEBAPP,
    ),
  };
};

export default useFeatures;
