import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  cancelledStatus,
  isLeadAMainBooking,
  isLeadASubBooking,
} from 'utils/lead/leadUtils';
import LeadModalContext from './LeadModalContext';

const useLeadGroupBookingValues = () => {
  const { t } = useTranslation();
  const { lead, groupBookingLeads, setCurrentLeadUid } =
    useContext(LeadModalContext);
  const { watch } = useFormContext();
  const { uid: leadUid } = useWatch();
  const updateMode = !!leadUid;

  const currentGroupBookingLeadUid = watch('currentGroupBookingLeadUid');

  useEffect(() => {
    if (currentGroupBookingLeadUid) {
      setCurrentLeadUid(currentGroupBookingLeadUid);
    }
  }, [currentGroupBookingLeadUid]);

  const showGroupBookingSelect =
    updateMode &&
    (isLeadAMainBooking(lead) || isLeadASubBooking(lead)) &&
    groupBookingLeads?.subLeads?.length > 0;

  const groupBookingOptions = showGroupBookingSelect
    ? [
        {
          label: t('componentLead.groupBooking.mainBooking'),
          value: groupBookingLeads.mainLead.uid,
        },
        ...groupBookingLeads.subLeads
          .sort((a, b) => a.unit?.name.localeCompare(b.unit?.name))
          .map((l) => ({
            label: cancelledStatus.includes(l.status)
              ? l.property?.name
              : l.unit?.name,
            value: l.uid,
            disabled: cancelledStatus.includes(l.status),
          })),
      ]
    : [];

  return {
    showGroupBookingSelect,
    groupBookingOptions,
  };
};

export default useLeadGroupBookingValues;
