import styled from 'styled-components';
import { ButtonToolbar, ModalHeader } from 'react-bootstrap';
import theme from 'styles/theme';
import { LeadStatus } from 'models/Leads';
import Button from 'components/button/Button';

const leadStatusToColor = {
  [LeadStatus.NEW]: theme.colors.bgGreen,
  [LeadStatus.ON_HOLD]: '#FFC454',
  [LeadStatus.BOOKED_BY_AGENT]: '#3A84D8',
  [LeadStatus.BLOCKED]: '#CED4DA',
};

export const LeadModalHeaderStyled = styled(ModalHeader).attrs({
  closeButton: true,
  'data-testid': 'lead-modal-header',
})`
  border-bottom: none;
  min-height: 90px;

  > .close {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .form-field-container {
    margin-bottom: 0;
    max-width: 50%;
  }

  .tags-selector .rbt-input {
    border: none;
    box-shadow: none;

    .rbt-input-main {
      min-width: 60px;
    }
  }
`;

export const LeadModalHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding-right: 15px;
  width: 100%;
`;

export const LeadModalHeaderSection = styled.div`
  align-items: center;
  display: flex;
`;

export const LeadModalChannelIconContainer = styled.div`
  height: 30px;
  margin: 0 5px;
  width: 30px;
`;

export const LeadModalHeaderButton = styled(Button)`
  display: flex;
  align-items: center;
  background: #f3f3f7;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-weight: bold;

  svg {
    margin-right: 5px;
  }
`;

export const StatusButtonsContainer = styled(ButtonToolbar)`
  margin-left: 10px;
`;

export const StatusButton = styled(LeadModalHeaderButton).attrs(
  ({ $status }: { $status: LeadStatus }) => ({
    'data-testid': `lead-status-button-${$status}`,
  }),
)<{
  $fill: boolean;
  $status: LeadStatus;
}>`
  ${({ $fill, $status }) =>
    $fill &&
    `
      background-color: ${leadStatusToColor[$status]};
        color: ${$status === LeadStatus.ON_HOLD ? 'black' : 'white'};
    `}

  ${({ $status }) => `
      :active, :focus, :hover {
        background-color: ${leadStatusToColor[$status]};
        border-color: ${leadStatusToColor[$status]};
        outline: none;
        color: ${
          $status === LeadStatus.BLOCKED || $status === LeadStatus.ON_HOLD
            ? 'black'
            : 'white'
        };
      }
    `}
`;

export const GuestLink = styled.a`
  color: ${theme.colors.fontDark};
`;

export const GroupBookingContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;

  .form-field-container {
    max-width: 100%;

    & > div {
      padding: 0;
      width: 100% !important;
    }
  }
`;

export const RightSidedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 12px;
  margin-right: 12px;
`;
