import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Channel } from 'models/Channels';
import AirbnbIcon from 'assets/images/icons/channels/airbnb.svg?react';
import useFetchPropertyChannelSettings from 'components/domain/channelSettings/useFetchPropertyChannelSettings';
import {
  AirbnbGuestReviewsCollapseLabel,
  AirbnbGuestReviewsCollapseStyled,
  AirbnbGuestReviewsContainer,
} from './AirbnbGuestReviews.styles';

import OverallAirbnbRatings from './overallAirbnbRatings/OverallAirbnbRatings';
import AutomatedReviews from './AutomatedReviews';
import GuestReviews from './guestReviews/GuestReviews';

const AirbnbGuestReviewsSection = () => {
  const { uid: propertyUid } = useParams();
  const { t } = useTranslation();
  const { channelSettings, isFetching } =
    useFetchPropertyChannelSettings(propertyUid);

  const isAirbnbActive = !!channelSettings?.find(
    ({ enumId }) => enumId === Channel.airbnb,
  )?.isActive;

  if (!isAirbnbActive || isFetching) {
    return null;
  }

  const title = (
    <AirbnbGuestReviewsCollapseLabel>
      <AirbnbIcon />
      {t('pageProperty.reviews.airbnbGuest.airbnbGuestReviews')}
    </AirbnbGuestReviewsCollapseLabel>
  );

  return (
    <AirbnbGuestReviewsCollapseStyled
      variant="card"
      label={title}
      defaultExpanded
      data-testid="airbnb-guest-reviews-section"
    >
      <AirbnbGuestReviewsContainer>
        <OverallAirbnbRatings />
        <AutomatedReviews />
        <GuestReviews />
      </AirbnbGuestReviewsContainer>
    </AirbnbGuestReviewsCollapseStyled>
  );
};

export default AirbnbGuestReviewsSection;
