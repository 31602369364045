import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import PriceRulesAddEditModal from './PriceRulesAddEditModal';

const modalId = 'price-rules-add-edit-modal';

const usePriceRulesAddEditModal = () => {
  const { openModal } = useAppModal();
  const { property } = usePropertySettingsInitData();
  const { t } = useTranslation();

  const openAddEditModal = () => {
    openModal({
      customContent: (
        <PriceRulesAddEditModal modalId={modalId} property={property} />
      ),
      id: modalId,
      title: t('pageProperty.pricing.priceRules.modal.addTitle'),
    });
  };

  const openAddPriceRulesModal = () => {
    openAddEditModal();
  };

  return { openAddPriceRulesModal };
};

export default usePriceRulesAddEditModal;
