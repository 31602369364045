import {
  format as formatDate,
  formatDistance,
  formatDistanceToNow,
  isDate,
} from 'date-fns';
import {
  enGB,
  enUS,
  fr,
  pt,
  es,
  pl,
  de,
  ru,
  ja,
  ko,
  nl,
  tr,
  vi,
  it,
} from 'date-fns/locale';
import { getUTCDate } from 'utils/dateTimeUtils';
import { getLanguageCode } from './i18n.utils';
import { LanguageCode } from './internationalization.types';

const dateTimeLocales = {
  [LanguageCode.DE]: de,
  [LanguageCode.EN]: enUS,
  [LanguageCode.EN_GB]: enGB,
  [LanguageCode.EN_US]: enUS,
  [LanguageCode.ES]: es,
  [LanguageCode.FR]: fr,
  [LanguageCode.JA]: ja,
  [LanguageCode.PL]: pl,
  [LanguageCode.PT]: pt,
  [LanguageCode.RU]: ru,
  [LanguageCode.IT]: it,
  [LanguageCode.KO]: ko,
  [LanguageCode.NL]: nl,
  [LanguageCode.TR]: tr,
  [LanguageCode.VI]: vi,
};

export function getDateTimeLocale(lang?: string): Locale {
  if (lang && dateTimeLocales[lang]) {
    return dateTimeLocales[lang];
  }

  if (dateTimeLocales[getLanguageCode(lang)]) {
    return dateTimeLocales[getLanguageCode(lang)];
  }

  return dateTimeLocales[LanguageCode.EN];
}

export function tryToFormatDateTime(value, format, lang) {
  if (isDate(value)) {
    const locale = getDateTimeLocale(lang);

    if (format === 'shortDate') {
      return formatDate(value, 'P', { locale });
    }

    if (format === 'mediumDate') {
      return value.toLocaleDateString(lang, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });
    }

    if (format === 'mediumDateWithoutYear') {
      return value.toLocaleDateString(lang, {
        day: 'numeric',
        month: 'short',
      });
    }

    if (format === 'mediumDateWithWeekDay') {
      return value.toLocaleDateString(lang, {
        day: 'numeric',
        month: 'short',
        weekday: 'short',
        year: 'numeric',
      });
    }

    if (format === 'mediumDateWithWeekDayWithoutYear') {
      return value.toLocaleDateString(lang, {
        day: 'numeric',
        month: 'short',
        weekday: 'short',
      });
    }

    if (format === 'longDate') {
      return formatDate(value, 'PPPP', { locale });
    }

    if (format === 'longDateTime') {
      return formatDate(value, 'PPPPp', { locale });
    }

    if (format === 'time') {
      return formatDate(value, 'p', { locale });
    }

    if (format === 'ago') {
      return formatDistanceToNow(value, {
        locale,
        addSuffix: true,
      });
    }

    if (format === 'agoInUTC') {
      const nowInUTC = getUTCDate(new Date());
      return formatDistance(value, nowInUTC, {
        locale,
        addSuffix: true,
      });
    }

    // more options docs: https://date-fns.org/v2.29.3/docs/format

    return formatDate(value, format || '', { locale });
  }

  return null;
}
