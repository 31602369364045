import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Path } from 'react-router-dom';
import { resetAll } from 'store/store.constants';

interface LoginState {
  requestedPath: Path;
  requestedUrl: string;
}

const initialState: LoginState = {
  requestedPath: null,
  requestedUrl: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setRequestedPath: (
      state,
      action: PayloadAction<{ requestedPath: Path }>,
    ) => {
      state.requestedPath = action.payload.requestedPath;
    },
    clearRequestedPath: (state) => {
      delete state.requestedPath;
    },
    setRequestedUrl: (
      state,
      action: PayloadAction<{ requestedUrl: string }>,
    ) => {
      state.requestedUrl = action.payload.requestedUrl;
    },
    clearRequestedUrl: (state) => {
      delete state.requestedUrl;
    },
  },
});

export const {
  clearRequestedPath,
  clearRequestedUrl,
  setRequestedPath,
  setRequestedUrl,
} = loginSlice.actions;

export default loginSlice.reducer;
