import PropertySettingsPricingManagementAlert from './PropertySettingsPricingManagementAlert';
import { PropertySettingsPricingTabStyledContainer } from './PropertySettingsPricingTab.styles';
import PriceRules from './sections/priceRules/PriceRules';
import PriceSettings from './sections/priceSettings/PriceSettings';

const PropertySettingsPricingTab = () => {
  return (
    <PropertySettingsPricingTabStyledContainer>
      <PropertySettingsPricingManagementAlert />
      <PriceSettings />
      <div className="divider" />
      <PriceRules />
    </PropertySettingsPricingTabStyledContainer>
  );
};

export default PropertySettingsPricingTab;
