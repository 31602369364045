import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';

import useAppUser from 'hooks/useAppUser';
import { isLeadAMainBooking } from 'utils/lead/leadUtils';
import { format } from 'date-fns';
import { QuoteGroupBookingResponse } from 'models/QuoteGroupBooking';
import { useWatch } from 'react-hook-form';

export const GROUP_BOOKING_QUERY_KEY = 'get-quote-group-booking';

const useWatchQuoteGroupBookingData = ({ lead }: { lead }) => {
  const { agencyUid } = useAppUser();
  const { checkInDate, checkOutDate, adultCount, childrenCount, propertyUid } =
    useWatch();
  const groupUid = lead?.groupUid;

  const { data: groupBooking, isFetching: isGroupBookingLoading } = useAppQuery(
    [GROUP_BOOKING_QUERY_KEY, groupUid],
    async () => {
      const response = await API.post<QuoteGroupBookingResponse>(
        '/api/internal/quotes/group-booking',
        {
          masterLeadUid: groupUid,
          agencyUid,
          propertyUid,
          checkInDate: format(checkInDate, 'yyyy-MM-dd'),
          checkOutDate: format(checkOutDate, 'yyyy-MM-dd'),
          guests: parseInt(adultCount, 10) + parseInt(childrenCount, 10),
        },
      );
      return response.data;
    },
    {
      enabled: !!agencyUid && !!groupUid && isLeadAMainBooking(lead),
    },
  );

  return {
    groupBooking,
    isGroupBookingLoading,
  };
};

export default useWatchQuoteGroupBookingData;
