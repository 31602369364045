import { SyntheticEvent, useEffect, useState } from 'react';
import { Col, Nav, NavItem, Row, TabContainer } from 'react-bootstrap';
import { useFieldArray, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { normalizeKey } from 'utils/localeUtils';
import Button from 'components/button/Button';
import { VerticalTabContainer } from 'components/tab/Tab.styles';
import { RoomType } from 'models/PropertyRooms';
import { classNames } from 'utils/classNameUtils';
import { RoomsAndBedTypesFormValues } from './RoomsAndBedTypesModal.schema';
import {
  RoomsDescriptionContainer,
  RoomsAndBedTypesColStyled,
  RoomsTabsContainer,
  RoomsTabsDivider,
} from './RoomsAndBedTypesFormElements.styles';
import RoomsAndBedTypesFormRoomTypeField from './RoomsAndBedTypesFormRoomTypeField';
import RoomsAndBedTypesFormBedTypes from './RoomsAndBedTypesFormBedTypes';
import RoomsAndBedTypesFormPrivateBathroomField from './RoomsAndBedTypesFormPrivateBathroomField';
import RoomsAndBedTypesFormSharedRoomField from './RoomsAndBedTypesFormSharedRoomField';
import RoomsAndBedTypesFormDeleteRoomButton from './RoomsAndBedTypesFormDeleteRoomButton';

const RoomsAndBedTypesFormElements = () => {
  const {
    fields: rooms,
    append,
    remove,
  } = useFieldArray<RoomsAndBedTypesFormValues, 'rooms'>({
    name: 'rooms',
  });
  const { errors } = useFormState();
  const [selectedRoomIndex, setSelectedRoomIndex] = useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (Object.keys(errors?.rooms || {}).length) {
      const currentRoomHasNoError = !Object.keys(errors.rooms).some(
        (roomIndex) => Number(roomIndex) === selectedRoomIndex,
      );

      if (currentRoomHasNoError) {
        const firstTabWithError = Number(Object.keys(errors.rooms)[0]);
        setSelectedRoomIndex(firstTabWithError);
      }
    }
  }, [errors]);

  const onSelect = (event: SyntheticEvent<TabContainer, Event>) => {
    const tabIndex = event as unknown as number;
    setSelectedRoomIndex(tabIndex);
  };

  const addRoomHandler = () => {
    const roomIndex = rooms.length;
    const roomNumber =
      rooms.reduce(
        (maxNumber, { roomNumber: roomNo }) => Math.max(maxNumber, roomNo),
        0,
      ) + 1;

    append({
      beds: [],
      bedTypes: '',
      hasPrivateBathroom: false,
      sharedRoom: false,
      roomNumber,
      roomType: RoomType.BEDROOM,
    });

    setSelectedRoomIndex(roomIndex);
  };

  const deleteRoomHandler = (roomIndex: number) => {
    remove(roomIndex);

    if (roomIndex > 0) {
      setSelectedRoomIndex(roomIndex - 1);
    }
  };

  let bedroomsCount = 0;
  let livingRoomsCount = 0;

  const getRoomNumberForType = (roomType: RoomType) => {
    if (roomType === RoomType.BEDROOM) {
      bedroomsCount += 1;
      return bedroomsCount;
    }

    livingRoomsCount += 1;
    return livingRoomsCount;
  };

  return (
    <>
      <RoomsDescriptionContainer>
        <h4 data-testid="rooms-and-bed-types-title">
          {t(
            'pageProperty.mainSettings.capacityDetails.setRoomsAndBedTypesModal.roomsAndBedTypes',
          )}
        </h4>
        <Button
          $colorScheme="green"
          data-testid="add-room-button"
          onClick={addRoomHandler}
        >
          {t(
            'pageProperty.mainSettings.capacityDetails.setRoomsAndBedTypesModal.addRoomButtonLabel',
          )}
        </Button>
      </RoomsDescriptionContainer>
      {!rooms?.length && (
        <div data-testid="no-rooms-message">
          {t(
            'pageProperty.mainSettings.capacityDetails.setRoomsAndBedTypesModal.noRoomsMessage',
          )}
        </div>
      )}
      {!!rooms?.length && (
        <>
          <RoomsTabsDivider />
          <VerticalTabContainer
            activeKey={selectedRoomIndex}
            onSelect={onSelect}
          >
            <Row>
              <RoomsAndBedTypesColStyled sm={4}>
                <Nav stacked>
                  {rooms.map(({ roomType }, index) => {
                    const roomNumber = getRoomNumberForType(roomType);
                    const roomTypeLocaleKey =
                      roomType === RoomType.BEDROOM ? 'bedroom' : 'livingRoom';

                    return (
                      <NavItem
                        data-testid={`room-tab-${index}`}
                        eventKey={index}
                        key={`${roomType}-${roomNumber}`}
                      >
                        {t(
                          normalizeKey(
                            `pageProperty.mainSettings.capacityDetails.setRoomsAndBedTypesModal.${roomTypeLocaleKey}Number`,
                          ),
                          { roomNumber },
                        )}
                      </NavItem>
                    );
                  })}
                </Nav>
              </RoomsAndBedTypesColStyled>
              <RoomsAndBedTypesColStyled sm={8}>
                {rooms.map(({ id }, index) => (
                  <RoomsTabsContainer
                    className={classNames({
                      'd-none': index !== selectedRoomIndex,
                    })}
                    data-testid={`room-details-${index}`}
                    key={id}
                  >
                    <RoomsAndBedTypesFormRoomTypeField roomIndex={index} />
                    <RoomsAndBedTypesFormPrivateBathroomField
                      roomIndex={index}
                    />
                    <RoomsAndBedTypesFormSharedRoomField roomIndex={index} />
                    <RoomsAndBedTypesFormBedTypes roomIndex={index} />
                    <RoomsAndBedTypesFormDeleteRoomButton
                      deleteRoomHandler={() => deleteRoomHandler(index)}
                    />
                  </RoomsTabsContainer>
                ))}
              </RoomsAndBedTypesColStyled>
            </Row>
          </VerticalTabContainer>
        </>
      )}
    </>
  );
};

export default RoomsAndBedTypesFormElements;
