import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import SelectField from 'components/forms/selectField/SelectField';
import Tooltip from 'components/tooltip/Tooltip';
import { normalizeKey } from 'utils/localeUtils';
import { FeeScopeEnum, FeeTypeEnum } from 'models/PropertyFees';
import { Channel } from 'models/Channels';
import useFetchPropertyChannelSettings from 'components/domain/channelSettings/useFetchPropertyChannelSettings';
import { Property } from 'models/Properties';
import { FeeAmountType } from 'models/Quote';
import { MappingTypeContainer } from './PropertyFeeModal.styles';
import { BY_LENGTH_OF_STAY_SCOPE_OPTION } from './useFieldsOptions';

interface MappingTypeFieldProps {
  name: string;
  label: string;
  channel: Channel;
  options: string[];
  icon: React.ReactElement;
  labelInputSizes: {
    colSmLabel: number;
    colSmInput: number;
  };
  propertyUid: Property['uid'];
}

const MappingTypeField: React.FC<MappingTypeFieldProps> = ({
  name,
  label,
  options,
  channel,
  icon,
  labelInputSizes,
  propertyUid,
}) => {
  const { t } = useTranslation();
  const isTypeTax = useWatch({ name: 'type' }) === FeeTypeEnum.TAX;
  const feeTypeField = useWatch({ name: 'fee.type' });
  const taxField = useWatch({ name: 'tax' });
  const scopeField = useWatch({ name: 'scope' });
  const { setValue } = useFormContext();
  const { channelSettings, isFetching } =
    useFetchPropertyChannelSettings(propertyUid);

  const isChannelActiveAtProperty = !!channelSettings?.find(
    ({ enumId }) => enumId === channel,
  )?.isActive;

  const amountType = isTypeTax ? taxField?.type : feeTypeField;
  const scopeValue =
    amountType === FeeAmountType.TAX ? scopeField.tax : scopeField.amount;
  const isScopePerPet = scopeValue === FeeScopeEnum.PER_PET;
  const isScopePerPetPerNight = scopeValue === FeeScopeEnum.PER_PET_PER_NIGHT;
  const isAirbnbExclusiveScope = isScopePerPet || isScopePerPetPerNight;
  const isAirbnbMappingType = channel === Channel.airbnb;
  const isScopeNotSupported = isAirbnbExclusiveScope && !isAirbnbMappingType;

  const isTaxConditionNotSupported =
    isTypeTax &&
    taxField?.conditions?.type === BY_LENGTH_OF_STAY_SCOPE_OPTION &&
    !isAirbnbMappingType;

  const isMappingTypeUnsupported =
    !isChannelActiveAtProperty ||
    isScopeNotSupported ||
    isTaxConditionNotSupported;

  const mappingTypeErrorClassName = isMappingTypeUnsupported ? 'has-error' : '';

  const getMappingTypeOptions = () => {
    if (isMappingTypeUnsupported) {
      return [
        {
          value: 'UNSUPPORTED',
          label: t(
            'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.notSupported',
          ),
        },
      ];
    }
    return [
      {
        value: '',
        label: t(
          'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.pleaseSelect',
        ),
      },
      ...options.map((value) => ({
        value,
        label: t(
          normalizeKey(
            `pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.channelSpecific.${name}Options.${value}`,
          ),
        ),
      })),
    ];
  };

  // Set undefined value when mapping type is unsupported
  useEffect(() => {
    if (isFetching) return;

    if (isMappingTypeUnsupported) {
      setValue(`${name}MappingType`, undefined);
    }
  }, [isMappingTypeUnsupported, isFetching]);

  const field = (
    <MappingTypeContainer className={mappingTypeErrorClassName}>
      <SelectField
        name={`${name}MappingType`}
        label={label}
        {...labelInputSizes}
        options={getMappingTypeOptions()}
        disabled={isMappingTypeUnsupported}
      />
      {icon}
    </MappingTypeContainer>
  );

  if (isMappingTypeUnsupported) {
    return (
      <Tooltip
        id={`tooltip-${name}`}
        text={t(
          !isChannelActiveAtProperty
            ? 'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.channelNotAvailableProperty'
            : 'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.channelNotAvailableScope',
        )}
      >
        {field}
      </Tooltip>
    );
  }

  return field;
};

export default MappingTypeField;
