import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { PHOTOS_BASE_QUERY_KEY } from 'components/domain/property/useFetchPhotos';
import { PHOTO_ORDER_MUTATION_KEY } from '../components/photoGrid/usePhotoManagement';
import { UPLOAD_PHOTO_MUTATION_KEY } from '../components/upload/useUploadPhotos';
import { DELETE_PHOTO_MUTATION_KEY } from '../components/photoGrid/photo/usePhotoActions';

const usePhotoMutationStatus = () => {
  const updatingPhotoOrderCount = useIsMutating({
    mutationKey: PHOTO_ORDER_MUTATION_KEY,
  });
  const uploadingPhotosCount = useIsMutating({
    mutationKey: UPLOAD_PHOTO_MUTATION_KEY,
  });
  const deletingPhotoCount = useIsMutating({
    mutationKey: DELETE_PHOTO_MUTATION_KEY,
  });
  const isFetchingPhotos = useIsFetching({
    queryKey: [PHOTOS_BASE_QUERY_KEY, 'get-all'],
  });

  const isUpdatingPhotos =
    updatingPhotoOrderCount > 0 ||
    uploadingPhotosCount > 0 ||
    deletingPhotoCount > 0;

  const isRefetchingPhotos = isFetchingPhotos > 0;

  return { isUpdatingPhotos, isRefetchingPhotos };
};

export default usePhotoMutationStatus;
