export const PropertySettingsTab = {
  amenities: 'amenities',
  channels: 'channels',
  customData: 'custom-data',
  descriptions: 'descriptions',
  feesAndPolicies: 'fees-and-policies',
  kpis: 'kpis',
  mainSettings: 'main-settings',
  owners: 'owners',
  photos: 'photos',
  pricing: 'pricing',
  reviews: 'reviews',
  serviceProviders: 'service-providers',
};

export const settingsTabSidePanelId = 'settings-tab-side-panel';

export type PropertySettingsTabType =
  (typeof PropertySettingsTab)[keyof typeof PropertySettingsTab];
