import { Row } from '@tanstack/react-table';
import Button from 'components/button/Button';
import { AirbnbGuestReview } from 'models/Reviews';

const GuestNameCell = ({ row }: { row: Row<AirbnbGuestReview> }) => {
  const handleClickGuest = () => {
    const { leadUid } = row.original;
    if (leadUid) {
      window.open(`#/pipeline?leadUid=${leadUid}`, '_blank');
    }
  };

  return (
    <Button
      bsStyle="link"
      data-testid="row-guest-name-lead-button"
      onClick={handleClickGuest}
    >
      {row.original.guestName}
    </Button>
  );
};

export default GuestNameCell;
