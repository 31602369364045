import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import { PropertyBusinessType } from 'models/Properties';
import { Photo } from 'models/Photos';
import UploadPhotoTile from '../upload/UploadPhotoTile';
import usePhotoMutationStatus from '../../hooks/usePhotoMutationStatus';
import { PhotoGridContainer } from './PhotoGrid.styles';
import PhotoCard from './photo/PhotoCard';
import usePhotoDragAndDrop from './usePhotoDragAndDrop';

interface PhotoGridProps {
  photos: Photo[];
}

const PhotoGrid = ({ photos = [] }: PhotoGridProps) => {
  const { property } = usePropertySettingsInitData();
  const { isUpdatingPhotos, isRefetchingPhotos } = usePhotoMutationStatus();
  const {
    handleDragStart,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
    handleDragEnd,
  } = usePhotoDragAndDrop({ photos });

  const isPropertyUnitType =
    property?.businessType === PropertyBusinessType.UNIT_TYPE;
  const isPropertySubUnit =
    property?.businessType === PropertyBusinessType.SUB_UNIT;

  const shouldShowCoverBadge = !(isPropertyUnitType || isPropertySubUnit);
  const shouldDisablePhotoGrid = isUpdatingPhotos || isRefetchingPhotos;

  return (
    <PhotoGridContainer
      $isDisabled={shouldDisablePhotoGrid}
      data-testid="photo-grid"
      aria-disabled={shouldDisablePhotoGrid}
    >
      {photos.map((photo, index) => (
        <div
          key={photo.uid}
          draggable
          onDragStart={(e) => handleDragStart(e, photo)}
          onDragEnter={(e) => handleDragEnter(e, photo)}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, photo)}
          onDragEnd={handleDragEnd}
          data-testid={`draggable-photo-grid-item-${photo.uid}`}
        >
          <PhotoCard
            photo={photo}
            isCover={index === 0 && shouldShowCoverBadge}
          />
        </div>
      ))}
      <UploadPhotoTile />
    </PhotoGridContainer>
  );
};

export default PhotoGrid;
