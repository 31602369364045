import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import useOpenAdjustmentModal from 'components/domain/adjustment/modal/useOpenAdjustmentModal';
import useLeadCustomDataMutations from 'components/domain/customData/useLeadCustomDataMutations';
import { CustomDataFieldType } from 'models/CustomDataField';
import usePinCodeMutations from '../../pinCode/usePinCodeMutations';
import useReviewByHostMutations from '../../review/useReviewByHostMutations';
import useLeadSynchronousMutations from '../useLeadSynchronousMutations';
import useAppEvent from '../../../../hooks/useAppEvent';
import useAppModal from '../../../../hooks/useAppModal';
import { AppEventType } from '../../../../models/Events';
import useOpenTemplateModal from '../../template/modal/useOpenTemplateModal';
import useTemplateModalParamsProvider from '../../template/modal/useTemplateModalParamsProvider';
import { getTemplateNameByLeadStatus } from '../../../../utils/lead/leadUtils';
import LeadModalContext from './LeadModalContext';
import { requiresRebooking } from './LeadModal.utils';
import useLeadSavingPayload from './useLeadSavingPayload';
import useSecurityDepositQueries from './tabs/paymentTimeline/useSecurityDepositQueries';

function isCustomDataDirty(customData, initialCustomData) {
  return (
    (customData?.value.text || null) !==
      (initialCustomData?.value.text || null) ||
    (customData?.value.longText || null) !==
      (initialCustomData?.value.longText || null)
  );
}

function isPinCodeDirty(pinCode, initialPinCode) {
  return (
    (pinCode?.pinCode || null) !== (initialPinCode?.pinCode || null) ||
    (pinCode?.pinCodeUrl || null) !== (initialPinCode?.pinCodeUrl || null)
  );
}

function isReviewByHostDirty(reviewByHost, initialReviewByHost) {
  return (
    reviewByHost?.cleanlinessRating !==
      initialReviewByHost?.cleanlinessRating ||
    reviewByHost?.communicationRating !==
      initialReviewByHost?.communicationRating ||
    reviewByHost?.notes !== initialReviewByHost?.notes ||
    reviewByHost?.overallRating !== initialReviewByHost?.overallRating ||
    reviewByHost?.respectRulesRating !== initialReviewByHost?.respectRulesRating
  );
}

const useLeadModalFormSubmit = () => {
  const { clearErrors } = useForm();
  const {
    lead,
    modalId,
    pinCode: initialPinCode,
    reviewByHost: initialFetchedReviewByHost,
    setApiError,
    quoteAdjustments,
    availableProperties,
  } = useContext(LeadModalContext);
  const { publish } = useAppEvent();
  const { closeModal } = useAppModal();
  const { getLeadCreateSavePayload, getLeadUpdateSavePayload } =
    useLeadSavingPayload();
  const { createLead, updateLead } = useLeadSynchronousMutations();
  const { createPinCode, updatePinCode } = usePinCodeMutations();
  const { markSDAsReceived, markSDAsReturned } = useSecurityDepositQueries();
  const { updateLeadCustomData } = useLeadCustomDataMutations();
  const { updateReviewByHost } = useReviewByHostMutations();
  const { createTemplatedModal, templateModalId } = useOpenTemplateModal();
  const { createAdjustmentModal } = useOpenAdjustmentModal();
  const templateModalParamsProvider = useTemplateModalParamsProvider();

  const initialReviewByHost = initialFetchedReviewByHost || lead?.reviewByHost;
  const initialCustomData = lead?.customData;

  return async (formValues, { nativeEvent }) => {
    clearErrors();
    setApiError(null);

    const openMailer = nativeEvent.submitter.id === 'submit-open-mailer';

    const { checkInDate, checkOutDate, status } = formValues;

    if (requiresRebooking({ checkInDate, checkOutDate, status })) {
      return;
    }
    const {
      pinCode,
      reviewByHost,
      securityDeposit,
      customData,
      ...leadToSave
    } = formValues;

    const { propertyUid, uid: leadUid } = leadToSave;
    let createdLeadUid;

    if (leadUid) {
      const leadSavingPayload = getLeadUpdateSavePayload(leadToSave);
      const updateResponse = await updateLead({
        leadUid,
        payload: leadSavingPayload,
      });

      if (updateResponse.apiErrorMessage) {
        setApiError(updateResponse.apiErrorMessage);
        return;
      }

      if (
        reviewByHost &&
        isReviewByHostDirty(reviewByHost, initialReviewByHost)
      ) {
        updateReviewByHost({ reviewByHost });
      }

      if (pinCode && isPinCodeDirty(pinCode, initialPinCode)) {
        if (pinCode.uid) {
          updatePinCode({ pinCode });
        } else {
          createPinCode({
            pinCode,
            leadUid,
            propertyUid,
            availableProperties,
          });
        }
      }

      if (customData?.length > 0) {
        customData.forEach((singleCustomData, index) => {
          if (isCustomDataDirty(singleCustomData, initialCustomData[index])) {
            const { value, uid, type } = singleCustomData;
            updateLeadCustomData({
              leadUid,
              text:
                type === CustomDataFieldType.TEXT ? value.text : value.longText,
              customDataFieldUid: uid,
            });
          }
        });
      }

      const { wasSecurityDepositReceived, wasSecurityDepositReturned } = lead;
      const wasSecurityDepositReceivedAndReturned =
        wasSecurityDepositReceived && wasSecurityDepositReturned;

      if (!wasSecurityDepositReceivedAndReturned && securityDeposit) {
        await markSDAsReceived();
      }

      if (
        wasSecurityDepositReceived &&
        !wasSecurityDepositReturned &&
        securityDeposit
      ) {
        await markSDAsReturned();
      }

      publish(AppEventType.LEAD_UPDATED, { lead: updateResponse });
    } else {
      const leadSavingPayload = getLeadCreateSavePayload(leadToSave);
      const createResponse = await createLead(leadSavingPayload);

      if (createResponse.apiErrorMessage) {
        setApiError(createResponse.apiErrorMessage);
        return;
      }

      publish(AppEventType.LEAD_CREATED, { lead: createResponse });
      createdLeadUid = createResponse.uid;
    }

    closeModal(modalId);

    if (openMailer) {
      const templateName = getTemplateNameByLeadStatus(status);

      if (templateName) {
        const params = templateModalParamsProvider({
          leadUid: leadUid || createdLeadUid,
          templateModalId,
          templateName,
        });

        createTemplatedModal(params);
      }
    }

    if (quoteAdjustments?.find((a) => !a.isPaid)) {
      createAdjustmentModal({ leadUid: leadUid || createdLeadUid });
    }
  };
};

export default useLeadModalFormSubmit;
