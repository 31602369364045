import useAppQuery from 'hooks/useAppQuery';
import { Lead } from 'models/Leads';
import API from 'services/API';
import useAppUser from 'hooks/useAppUser';

const useFetchLeadsForGroupBookings = ({
  leadUid,
  fields,
  enabled = true,
}: {
  leadUid: string;
  fields: string;
  enabled?: boolean;
}) => {
  const { agencyUid } = useAppUser();

  const {
    data: leads,
    error,
    isInitialLoading: isFetching,
    refetch,
  } = useAppQuery(
    ['leads-for-group-bookings', leadUid, fields],
    async (): Promise<Lead[]> => {
      const response = await API.post('/v3/graphql', {
        query: `{leads (
            agencyUid:"${agencyUid}", 
            uids:["${leadUid}"],
            onlyShowSubLeadsForGroupBookings: true
          ){
            ${fields}
          }}`,
        variables: {},
      });

      const leadsResponse = response?.data?.data?.leads || [];

      // this is necessary because the leads response includes the lead itself
      const subLeads = leadsResponse.filter((lead) => lead.uid !== leadUid);

      return subLeads;
    },
    {
      enabled: enabled && !!leadUid,
    },
  );

  return {
    error,
    isFetching,
    refetch,
    leads,
  };
};

export default useFetchLeadsForGroupBookings;
