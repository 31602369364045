import { useTranslation } from 'react-i18next';
import FormWithProvider from 'components/forms/form/Form';
import usePropertiesUpdatingAllowed from 'components/domain/property/usePropertiesUpdatingAllowed';
import { AmenityCategory } from 'models/Amenity';
import { Channel } from 'models/Channels';
import ChannelFilter from 'pages/property/common/channelFilter/ChannelFilter';
import useEnabledChannels from 'pages/property/common/channelFilter/useEnabledChannels';
import PropertyEditPermissionInfo from '../../../common/PropertyEditPermissionInfo';
import useAmenitiesDataMap from '../../useAmenitiesDataMap';
import useFetchAvailableAmenities from '../../useFetchAvailableAmenities';
import usePropertyAmenities from '../../usePropertyAmenities';
import CategoryCollapseSection from './CategoryCollapseSection';
import FormFooterActions from './FormFooterActions';
import useCategorizedAmenitiesFormSubmit from './useCategorizedAmenitiesFormSubmit';
import CategorizedAmenitiesLoadingPlaceholder from './CategorizedAmenitiesLoadingPlaceholder';
import PopularAmenitiesSection from './popularAmenitiesSection/PopularAmenitiesSection';
import {
  categorizedAmenitiesSchema,
  getFormDefaultValues,
} from './CategorizedAmenities.schema';
import CategorizedAmenitiesEventListeners from './CategorizedAmenitiesEventListeners';

const supportedChannels = [
  Channel.orbirental,
  Channel.airbnb,
  Channel.homeaway,
  Channel.booking_com,
  Channel.google,
  Channel.hvmi,
];

const CategorizedAmenities = () => {
  const { t } = useTranslation();
  const {
    channels,
    activeChannels,
    toggleChannel,
    isFetching: isFetchingEnabledChannels,
  } = useEnabledChannels({
    defaultEnabledChannels: [Channel.orbirental],
    supportedChannels,
  });
  const { availableAmenities, isLoading: isLoadingAvailableAmenities } =
    useFetchAvailableAmenities({
      activeChannels,
    });
  const { propertyAmenities, isLoading: isLoadingPropertyAmenities } =
    usePropertyAmenities(availableAmenities?.length > 0);
  const { categorizedAmenities, popularAmenities } =
    useAmenitiesDataMap(availableAmenities);
  const { isUpdating, onSubmit } = useCategorizedAmenitiesFormSubmit();
  const propertiesUpdatingAllowed = usePropertiesUpdatingAllowed();

  const { INDOOR, OUTDOOR, FAMILY, EXTRAS } = categorizedAmenities || {};

  const isLoading =
    isFetchingEnabledChannels ||
    isLoadingAvailableAmenities ||
    isLoadingPropertyAmenities;

  if (isLoading) {
    return <CategorizedAmenitiesLoadingPlaceholder />;
  }

  const defaultValues = getFormDefaultValues(Object.values(propertyAmenities));

  return (
    <FormWithProvider
      data-testid="categorized-amenities-form"
      defaultValues={defaultValues}
      disabled={!propertiesUpdatingAllowed}
      onSubmit={onSubmit}
      resolver={categorizedAmenitiesSchema()}
      dirtyChecker
      noValidate
    >
      <fieldset disabled={isUpdating || !propertiesUpdatingAllowed}>
        <CategorizedAmenitiesEventListeners />

        <PropertyEditPermissionInfo />

        <ChannelFilter
          channels={channels}
          label={t('pageProperty.amenities.showSupportedAmenitiesChannel')}
          toggleChannel={toggleChannel}
        />

        <PopularAmenitiesSection popularAmenities={popularAmenities} />

        <CategoryCollapseSection
          category={INDOOR}
          type={AmenityCategory.INDOOR}
        />

        <CategoryCollapseSection
          category={OUTDOOR}
          type={AmenityCategory.OUTDOOR}
        />

        <CategoryCollapseSection
          category={FAMILY}
          type={AmenityCategory.FAMILY}
        />

        <CategoryCollapseSection
          category={EXTRAS}
          type={AmenityCategory.EXTRAS}
        />
        <FormFooterActions />
      </fieldset>
    </FormWithProvider>
  );
};
export default CategorizedAmenities;
