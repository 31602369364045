import { useRef } from 'react';
import { Photo } from 'models/Photos';
import usePhotoManagement from './usePhotoManagement';

interface UsePhotoDragAndDropProps {
  photos: Photo[];
}

const usePhotoDragAndDrop = ({ photos }: UsePhotoDragAndDropProps) => {
  const { handleUpdatePhotoOrder } = usePhotoManagement();
  const dragPhoto = useRef<Photo | null>(null);
  const draggedOverPhoto = useRef<Photo | null>(null);

  const handleDragStart = (e: React.DragEvent, photo: Photo) => {
    dragPhoto.current = photo;
    e.currentTarget.classList.add('dragging');

    if (e.dataTransfer) {
      e.dataTransfer.effectAllowed = 'move';
    }
  };

  const handleDragEnter = (e: React.DragEvent, photo: Photo) => {
    e.preventDefault();
    draggedOverPhoto.current = photo;
    const target = e.currentTarget as HTMLElement & { dragCounter?: number };
    target.dragCounter = (target.dragCounter || 0) + 1;
    if (target.dragCounter === 1) {
      target.classList.add('drag-over');
    }
  };

  const handleDragLeave = (e: React.DragEvent) => {
    const target = e.currentTarget as HTMLElement & { dragCounter?: number };
    target.dragCounter = (target.dragCounter || 1) - 1;
    if (target.dragCounter <= 0) {
      target.dragCounter = 0;
      target.classList.remove('drag-over');
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent, targetPhoto: Photo) => {
    e.preventDefault();
    const target = e.currentTarget as HTMLElement & { dragCounter?: number };
    target.dragCounter = 0;
    target.classList.remove('drag-over');

    if (!dragPhoto.current) return;

    if (dragPhoto.current.uid === targetPhoto.uid) {
      dragPhoto.current = null;
      draggedOverPhoto.current = null;
      return;
    }

    const targetPhotoIndex = photos.findIndex((p) => p.uid === targetPhoto.uid);

    if (targetPhotoIndex > -1) {
      handleUpdatePhotoOrder({
        photo: dragPhoto.current,
        order: targetPhotoIndex,
      });
    }

    dragPhoto.current = null;
    draggedOverPhoto.current = null;
  };

  const handleDragEnd = (e: React.DragEvent) => {
    e.currentTarget.classList.remove('dragging');
    const target = e.currentTarget as HTMLElement & { dragCounter?: number };
    target.dragCounter = 0;
    dragPhoto.current = null;
    draggedOverPhoto.current = null;
  };

  return {
    handleDragStart,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
    handleDragEnd,
  };
};

export default usePhotoDragAndDrop;
