import { useTranslation } from 'react-i18next';
import { Photo } from 'models/Photos';
import {
  PhotoCardContainer,
  PhotoImage,
  PhotoCoverBadge,
} from '../PhotoGrid.styles';
import PhotoActions from './PhotoActions';
import PhotoDescription from './PhotoDescription';
import usePhotoActions from './usePhotoActions';

interface PhotoCardProps {
  photo: Photo;
  isCover: boolean;
}

const PhotoCard = ({ photo, isCover }: PhotoCardProps) => {
  const { handleEditPhoto } = usePhotoActions(photo);
  const { t } = useTranslation();

  return (
    <PhotoCardContainer
      data-testid={`photo-card-${photo.uid}`}
      $isCover={isCover}
    >
      <div className="photo-card-content">
        <PhotoImage
          src={photo.largeThumbnailScaleImageUrl}
          alt={photo.description}
          onClick={handleEditPhoto}
        />
        <PhotoActions photo={photo} />
        {isCover && (
          <PhotoCoverBadge data-testid="photo-card-cover-badge">
            {t('pageProperty.photos.cover')}
          </PhotoCoverBadge>
        )}
      </div>

      <PhotoDescription description={photo.description} />
    </PhotoCardContainer>
  );
};

export default PhotoCard;
