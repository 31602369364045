import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const DividerLabel = styled.p`
  width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-top: 15px;
  margin-bottom: 25px;

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin-left: 8px;
  }
`;

export const MappingTypeContainer = styled.div`
  position: relative;

  .form-field-container div {
    padding-right: 50px;
  }

  & > svg {
    position: absolute;
    right: 50px;
    top: 5px;
    width: 24px;
    height: 24px;
  }

  &.has-error {
    select {
      color: #a94442;
    }

    svg {
      opacity: 0.6;
    }
  }
`;

export const AppliesToHostfullyContainer = styled(MappingTypeContainer)`
  .form-field-container div div {
    margin-top: 0;
  }

  .form-group {
    margin-bottom: 0;
  }

  .row {
    margin-bottom: 14px;

    span {
      position: absolute;
      right: 27px;
      top: 23px;
    }
  }
`;

export const ConditionContainer = styled.div`
  .form-field-container {
    margin-bottom: 0;
  }
`;

export const IsOptionalFieldRow = styled(Row)`
  span {
    margin-top: 2px;
  }

  .checkbox {
    padding-top: 0;
  }
`;
