/* eslint-disable react/require-default-props */
import { memo, useContext, useEffect, useState } from 'react';
import { differenceInCalendarDays, isAfter, isBefore } from 'date-fns';
import useAppSelector from 'hooks/useAppSelector';
import CalendarContext from 'pages/stackedCalendar/CalendarContext';
import { CalendarLead } from 'pages/stackedCalendar/Calendar.types';
import {
  blockedLeadGradientPrimaryColor,
  convertLeadChannelFromLeadSource,
  getColorSource,
  getFullName,
  getLabelZIndexFromLeadSource,
  getLeadCheckInDate,
  getLeadCheckOutDate,
  isFromICS,
  isLeadAMainBooking,
  isLeadASubBooking,
} from 'utils/lead/leadUtils';
import { Lead, LeadStatus } from 'models/Leads';
import { PropertyBusinessType } from 'models/Properties';
import LeadChannelIcon from 'components/domain/lead/LeadChannelIcon';
import LeadTooltip from 'components/domain/lead/LeadTooltip';
import { stackedCalendarStyleConstants } from 'pages/stackedCalendar/Calendar.constants';
import CalendarScrollPositionService from 'pages/stackedCalendar/CalendarScrollPositionService';
import CalendarBodySelectionService from 'pages/calendar/common/body/selection/CalendarBodySelectionService';
import { getLeadsOverlappingElement } from 'pages/calendar/Calendar.utils';
import { LeadViewContainer } from './LeadView.styles';
import { getLeadLabelLeftPosition } from './LeadView.utils';

function getWidthCheckInDay(totalCellWidth: number) {
  return totalCellWidth * 0.37;
}
function getWidthCheckOutDay(totalCellWidth: number) {
  return totalCellWidth * 0.18;
}

const LeadView = memo(({ lead }: { lead: CalendarLead }) => {
  const [tooltipLeft, setTooltipLeft] = useState({
    pos: 0,
    isOverlapping: false,
  });
  const { mode, bodyWidth, propertiesMap, leadsMap } =
    useContext(CalendarContext);
  const visibleDates = useAppSelector(
    (state) => state.stackedCalendar.visibleDates,
  );
  const cellWidth = stackedCalendarStyleConstants.daysCellWidth[mode];

  const isSubBooking = isLeadASubBooking(lead as Lead);
  const isMainBooking = isLeadAMainBooking(lead as Lead);

  if (!lead || isMainBooking) {
    return null;
  }

  const renderedCheckInDay = isBefore(getLeadCheckInDate(lead), visibleDates[1])
    ? visibleDates[1]
    : getLeadCheckInDate(lead);

  const renderedCheckOutDay = isAfter(
    getLeadCheckOutDate(lead),
    visibleDates[visibleDates.length - 2],
  )
    ? visibleDates[visibleDates.length - 2]
    : getLeadCheckOutDate(lead);

  const getWidth = () => {
    const numDaysDiff = differenceInCalendarDays(
      renderedCheckOutDay,
      renderedCheckInDay,
    );

    if (numDaysDiff === 0) {
      return getWidthCheckInDay(cellWidth);
    }

    if (numDaysDiff === 1) {
      return getWidthCheckInDay(cellWidth) + getWidthCheckOutDay(cellWidth);
    }

    const remainingDaysInPixels = (numDaysDiff - 1) * cellWidth;
    return (
      getWidthCheckInDay(cellWidth) +
      getWidthCheckOutDay(cellWidth) +
      remainingDaysInPixels
    );
  };

  const isOwner = lead.creator && lead.creator.type === 'OWNER';

  const isBlocked = lead.status === LeadStatus.BLOCKED || isOwner;

  const onMouseLeave = () => {
    setTooltipLeft({
      pos: 0,
      isOverlapping: false,
    });
  };

  const leadWidth = getWidth();

  const onMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (tooltipLeft.pos === 0) {
      const element = e.target as HTMLDivElement;
      const boundingRect = element.getBoundingClientRect();
      const offsetX = e.clientX - boundingRect.left;

      const bodyDayCellLeads = getLeadsOverlappingElement(element);

      setTooltipLeft({
        pos: offsetX,
        isOverlapping: bodyDayCellLeads?.length > 1,
      });
    }
  };

  const updateLeftPosition = (scrollPosition) => {
    const leadViewElt = document.getElementById(`lead-view-${lead.uid}`);
    const startDateElt = document.getElementsByClassName(
      'body-inactive-day-cell',
    )[0] as HTMLElement;

    if (!leadViewElt || !startDateElt) {
      return;
    }

    const startDate = new Date(Number(startDateElt.dataset.dayDate));

    const leftPosition = getLeadLabelLeftPosition({
      mode,
      scrollPosition: scrollPosition || 0,
      startDate,
      renderedCheckInDay,
      renderedCheckOutDay,
      leadWidth,
      labelWidth: leadViewElt.getBoundingClientRect().width + 10,
    });

    leadViewElt.style.left = `${leftPosition}px`;
  };

  useEffect(() => {
    const subscription = CalendarScrollPositionService.subscribe(
      (calendarScrollingPosition) => {
        updateLeftPosition(calendarScrollingPosition?.scrollLeft);
      },
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [mode, bodyWidth]);

  useEffect(() => {
    updateLeftPosition(
      CalendarScrollPositionService.getScrollingContainer()
        .calendarScrollingPosition?.scrollLeft,
    );
  }, []);

  const zIndex = getLabelZIndexFromLeadSource(lead.source, lead.status);

  const leadName = isSubBooking
    ? (leadsMap[lead.groupUid] as Lead)
    : (lead as Lead);

  return (
    <LeadViewContainer
      width={leadWidth}
      isFromICS={isFromICS(lead.source)}
      data-main-booking-uid={isSubBooking ? lead.groupUid : null}
      data-lead-uid={lead.uid}
      data-lead-status={lead.status}
      data-testid={`lead-view-${lead.uid}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      $zIndex={zIndex}
      // only draggable if lead's property is a unitType
      draggable={
        propertiesMap[lead.property.uid]?.businessType ===
        PropertyBusinessType.UNIT_TYPE
      }
      onDragStart={(e) => {
        e.dataTransfer.effectAllowed = 'move';
        const colorSource = getColorSource(mode, lead);
        CalendarBodySelectionService.setDraggingLead({
          leadUid: lead.uid,
          leadPropertyUid: lead.property.uid,
          leadUnitUid: lead.unitUid,
          checkIn: getLeadCheckInDate(lead),
          checkOut: getLeadCheckOutDate(lead),
          bgColor: colorSource,
          borderColor: isBlocked
            ? blockedLeadGradientPrimaryColor[isOwner ? 'owner' : 'other']
            : colorSource,
          widthPixels: leadWidth,
          leadStatus: lead.status,
        });

        const element = e.nativeEvent.target as HTMLElement;
        element.style.background = colorSource;
        element.style.opacity = '0.8';
        element.style.borderRadius = '5px';
        e.dataTransfer.setDragImage(element, 0, 0);

        // set opacity to 50% for all the day cells from that lead:
        const allIndividuallyDayCellLeads = document.querySelectorAll(
          `[data-lead-uid="${lead.uid}"].day-cell-lead-container`,
        );
        allIndividuallyDayCellLeads.forEach((leadElement) => {
          leadElement.setAttribute('style', `opacity: 0.5`);
        });

        e.dataTransfer.setData(
          'leadUid',
          JSON.stringify({
            color: colorSource,
            leadUid: lead.uid,
          }),
        );
      }}
    >
      <LeadTooltip
        lead={lead as Lead}
        wrapperLeft={tooltipLeft.pos}
        isOverlapping={tooltipLeft.isOverlapping}
      />
      <div className="lead-view-body" id={`lead-view-${lead.uid}`}>
        {!isBlocked && (
          <LeadChannelIcon
            source={lead.source}
            status={lead.status}
            channel={convertLeadChannelFromLeadSource(lead.source)}
            whiteVersion
          />
        )}
        <span className="lead-view-name">{getFullName(leadName)}</span>
      </div>
    </LeadViewContainer>
  );
});

export default LeadView;
