import { Col } from 'react-bootstrap';
import { DisplayFieldRowStyled } from './ViewAirbnbGuestReviewsModal.styles';

interface DisplayFieldProps {
  label: React.ReactNode;
  value: React.ReactNode;
  id: string;
}

const DisplayField = ({ label, value, id }: DisplayFieldProps) => {
  return (
    <DisplayFieldRowStyled>
      <Col xs={3}>
        <label htmlFor={id}>{label}</label>
      </Col>
      <Col xs={9}>
        <div id={id}>{value}</div>
      </Col>
    </DisplayFieldRowStyled>
  );
};

export default DisplayField;
