import { useIsMutating } from '@tanstack/react-query';
import Button from 'components/button/Button';
import FormWithProvider from 'components/forms/form/Form';
import TextField from 'components/forms/textField/TextField';
import useAppModal from 'hooks/useAppModal';
import { CancellationPolicyValue } from 'models/CancellationPolicy';
import { Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';

export const editModalId = 'edit-cancellation-policy-modal';

const editSchema = () => ({
  text: Yup.string().max(1000).required(),
});

export type CancellationPolicyEditFormSchemaType = Yup.InferType<
  OptionalObjectSchema<ReturnType<typeof editSchema>>
>;

const EditCancellationPolicyModal = ({
  onSubmit,
  cancellationPolicyValue,
  mutationKey,
}: {
  onSubmit: (data: CancellationPolicyEditFormSchemaType) => void;
  cancellationPolicyValue: CancellationPolicyValue;
  mutationKey: string[];
}) => {
  const { closeModal } = useAppModal();
  const { t } = useTranslation();
  const isMutating = useIsMutating({
    mutationKey,
  });

  const isUpdating = isMutating > 0;

  const handleClose = () => {
    closeModal(editModalId);
  };

  return (
    <FormWithProvider
      schema={editSchema()}
      onSubmit={onSubmit}
      defaultValues={{
        text: cancellationPolicyValue.text,
      }}
    >
      <Modal.Header>
        <Modal.Title>
          {t(
            'pageProperty.feesTaxesAndPolicies.cancellationPolicy.modal.editTitle',
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="d-flex flex-column">
          <TextField
            name="text"
            label={t(
              'pageProperty.feesTaxesAndPolicies.cancellationPolicy.modal.text',
            )}
            componentClass="textarea"
            required
            colSmInput={8}
            colSmLabel={4}
            rows={6}
            maxCharacters={1000}
            maxLength={1000}
          />
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button bsStyle="default" onClick={handleClose}>
          {t('common.cancel')}
        </Button>
        <Button bsStyle="primary" type="submit" disabled={isUpdating}>
          {t('common.save')}
        </Button>
      </Modal.Footer>
    </FormWithProvider>
  );
};

export default EditCancellationPolicyModal;
