import { useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useAppEvent from 'hooks/useAppEvent';
import useAppModal from 'hooks/useAppModal';
import { AppEventType } from 'models/Events';
import { LeadStatus } from 'models/Leads';
import { openInboxForLead } from 'utils/inbox/inboxUtils';
import {
  canSendMessage,
  isLeadAMainBooking,
  isLeadASubBooking,
} from 'utils/lead/leadUtils';
import { normalizeKey } from 'utils/localeUtils';
import DollarIcon from 'assets/images/icons/dollar.svg?react';
import PencilIcon from 'assets/images/icons/pencil.svg?react';
import HomeIcon from 'assets/images/icons/home.svg?react';
import useFeatures from 'hooks/useFeatures';
import useAppUser from 'hooks/useAppUser';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import SelectField from 'components/forms/selectField/SelectField';
import LeadChannelIcon from '../LeadChannelIcon';
import LeadCircle from '../status/LeadCircle';
import LeadMultiReservationLabel from '../LeadMultiReservationLabel';
import LeadModalContext from './LeadModalContext';
import {
  GroupBookingContainer,
  LeadModalChannelIconContainer,
  LeadModalHeaderButton,
  LeadModalHeaderContainer,
  LeadModalHeaderSection,
  LeadModalHeaderStyled,
  RightSidedContainer,
  StatusButton,
  StatusButtonsContainer,
} from './LeadModalHeader.styles';
import LeadModalHeaderTitle from './LeadModalHeaderTitle';
import LeadModalHeaderTags from './LeadModalHeaderTags';
import { LeadModalParams } from './LeadModal.types';
import useLeadGroupBookingValues from './useLeadGroupBookingValues';

const newLeadAvailableStatuses = [
  LeadStatus.NEW,
  LeadStatus.ON_HOLD,
  LeadStatus.BOOKED_BY_AGENT,
  LeadStatus.BLOCKED,
];

const LeadModalHeader = ({
  params: { jobCreationNotAllowed },
}: {
  params: LeadModalParams;
}) => {
  const { t } = useTranslation();
  const { publish } = useAppEvent();
  const { closeModal } = useAppModal();
  const { lead, modalId } = useContext(LeadModalContext);
  const { setValue } = useFormContext();
  const { allowOrderViewWebapp } = useFeatures();
  const { isEmployeeManager } = useAppUser();
  const { showGroupBookingSelect, groupBookingOptions } =
    useLeadGroupBookingValues();

  const {
    checkInDate,
    checkOutDate,
    propertyUid,
    status: currentStatus,
    uid: leadUid,
  } = useWatch();
  const updateMode = !!leadUid;

  const sendMessageHandler = () => {
    openInboxForLead(leadUid);
  };

  const statusButtonClickHandler = (status: LeadStatus) => {
    // TODO delete once modals merged
    if (status === LeadStatus.BLOCKED) {
      closeModal(modalId);
      publish(AppEventType.OPEN_CREATE_BLOCK_MODAL, {
        dateFrom: checkInDate,
        dateTo: checkOutDate,
        propertyUid,
      });
    } else {
      setValue('status', status);
    }
  };

  const onCreateJobClick = () => {
    closeModal(modalId);
    publish(AppEventType.OPEN_CREATE_JOB_MODAL, {
      jobDate: checkInDate,
      propertyUid,
    });
  };

  return (
    <LeadModalHeaderStyled>
      <LeadModalHeaderContainer>
        <LeadModalHeaderSection>
          {updateMode && lead && (
            <>
              <LeadCircle lead={lead} />
              <LeadModalChannelIconContainer>
                <LeadChannelIcon channel={lead.channel?.channel} />
              </LeadModalChannelIconContainer>
            </>
          )}
          <LeadModalHeaderTitle />
          <LeadModalHeaderTags />
        </LeadModalHeaderSection>

        <RightSidedContainer>
          {showGroupBookingSelect && (
            <GroupBookingContainer>
              <LeadMultiReservationLabel lead={lead} />
              <SelectField
                name="currentGroupBookingLeadUid"
                options={groupBookingOptions}
              />
            </GroupBookingContainer>
          )}

          <LeadModalHeaderSection>
            <ButtonToolbar>
              {updateMode &&
                isEmployeeManager &&
                lead.order &&
                lead.order.uid &&
                !isLeadASubBooking(lead) && (
                  <LeadModalHeaderButton
                    href={
                      allowOrderViewWebapp
                        ? `#/order/${lead.order.uid}`
                        : `#/order-view?o=${lead.order.uid}`
                    }
                    data-testid="view-order-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <DollarIcon />
                    {t('componentOrder.viewOrder')}
                  </LeadModalHeaderButton>
                )}

              {!jobCreationNotAllowed &&
                !isLeadAMainBooking(lead) &&
                !isLeadASubBooking(lead) && (
                  <LeadModalHeaderButton
                    data-testid="create-job-button"
                    onClick={onCreateJobClick}
                  >
                    <HomeIcon />
                    {t('componentLead.modal.createJob')}
                  </LeadModalHeaderButton>
                )}

              {updateMode &&
                lead &&
                canSendMessage(lead) &&
                !isLeadASubBooking(lead) && (
                  <LeadModalHeaderButton
                    data-testid="send-message-button"
                    onClick={sendMessageHandler}
                  >
                    <PencilIcon />
                    {t('componentLead.modal.sendMessage')}
                  </LeadModalHeaderButton>
                )}
            </ButtonToolbar>

            {!updateMode && (
              <StatusButtonsContainer>
                {newLeadAvailableStatuses.map((status) => (
                  <StatusButton
                    $fill={status === currentStatus}
                    $status={status}
                    key={status}
                    onClick={() => statusButtonClickHandler(status)}
                  >
                    {t(
                      normalizeKey(
                        `componentLead.modal.newLeadStatus.${status}`,
                      ),
                    )}
                  </StatusButton>
                ))}
              </StatusButtonsContainer>
            )}
          </LeadModalHeaderSection>
        </RightSidedContainer>
      </LeadModalHeaderContainer>
    </LeadModalHeaderStyled>
  );
};

export default LeadModalHeader;
