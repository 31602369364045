import { useTranslation } from 'react-i18next';
import { Glyphicon } from 'react-bootstrap';
import { UploadPhotoButtonStyled } from './UploadPhoto.styles';
import UploadPhotoWrapper from './UploadPhotoWrapper';

const UploadPhotoButton = () => {
  const { t } = useTranslation();

  return (
    <UploadPhotoWrapper id="upload-photo-button">
      <UploadPhotoButtonStyled bsStyle="primary">
        <Glyphicon glyph="plus" />
        {t('common.upload')}
      </UploadPhotoButtonStyled>
    </UploadPhotoWrapper>
  );
};

export default UploadPhotoButton;
