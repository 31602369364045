import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import useAppModal from 'hooks/useAppModal';
import {
  AirbnbFeeType,
  AirbnbFeeUnitType,
  BookingDotComFeeType,
  FeeConditionEnum,
  FeeTypeEnum,
  HostfullyFeeType,
  HVMIFeeType,
  PropertyInternalFee,
  VrboFeeType,
} from 'models/PropertyFees';
import { FeeAmountType } from 'models/Quote';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import { isNotSelectAll } from 'components/forms/multiSelectField/MultiSelectField.constants';
import PropertyFeeModal, {
  ADD_PROPERTY_FEE_MODAL_ID,
  EDIT_PROPERTY_FEE_MODAL_ID,
} from './PropertyFeeModal';
import { PropertyFeeFormSchemaType } from './PropertyFeeModal.schema';
import usePropertyFeeModalQueries, {
  ADD_PROPERTIES_FEES_BASE_QUERY_KEY,
  UPDATE_PROPERTIES_FEES_BASE_QUERY_KEY,
} from './usePropertyFeeModalQueries';
import { BY_LENGTH_OF_STAY_SCOPE_OPTION } from './useFieldsOptions';

const useOpenPropertyFeeModal = () => {
  const { openModal } = useAppModal();
  const { createFee, updateFee } = usePropertyFeeModalQueries();
  const { property, isLoading } = usePropertySettingsInitData();

  const propertyCurrency =
    property?.pricing?.currency && property.pricing.currency !== 'NONE'
      ? property.pricing.currency
      : 'USD';

  const handleSubmit = (
    values: PropertyFeeFormSchemaType,
    type: 'EDIT' | 'CREATE',
  ) => {
    const isTax = values.type === FeeTypeEnum.TAX;
    const amount = isTax ? values.tax?.value : values.fee?.value;
    const amountType = isTax ? values.tax?.type : values.fee?.type;
    const conditionsObject = values.tax?.conditions as {
      lengthOfStayValue: number;
      lengthOfStayType: FeeConditionEnum;
      type: string;
    };

    const conditionsLOSDays = conditionsObject?.lengthOfStayValue;
    const conditions =
      conditionsObject?.type === BY_LENGTH_OF_STAY_SCOPE_OPTION
        ? conditionsObject?.lengthOfStayType
        : FeeConditionEnum.NONE;

    const hostfullyType =
      values.appliesToHostfully === 'YES' ? HostfullyFeeType.DEFAULT : null;
    const scope =
      amountType === FeeAmountType.TAX ? values.scope.tax : values.scope.amount;

    const applyToAllProperties =
      values.appliesTo === PROPERTIES_SELECTION.ALL_PROPERTIES;

    const appliesToPropertiesFiltered =
      values.appliesToProperties?.filter(isNotSelectAll);

    const payload = {
      uid: values?.uid,
      type: values.type as FeeTypeEnum,
      name: values.name,
      amount,
      amountType,
      taxationRate: values.fee?.taxationRate,
      scope,
      conditions,
      conditionsLOSDays,
      optional: values.optional,
      airbnbType: (values.airbnbMappingType as AirbnbFeeType) ?? null,
      airbnbUnitType:
        (values.airbnbUnitMappingType as AirbnbFeeUnitType) ?? null,
      vrboType: (values.vrboMappingType as VrboFeeType) ?? null,
      bookingDotComType:
        (values.bookingMappingType as BookingDotComFeeType) ?? null,
      hvmiType: (values.hvmbMappingType as HVMIFeeType) ?? null,
      hostfullyType,
      allProperties: applyToAllProperties,
      propertiesUids: applyToAllProperties
        ? []
        : appliesToPropertiesFiltered.map((item) => item.value),
    };

    if (type === 'CREATE') {
      createFee(payload);
    } else {
      updateFee(payload);
    }
  };

  const openAddModal = () => {
    openModal({
      id: ADD_PROPERTY_FEE_MODAL_ID,
      customContent: (
        <PropertyFeeModal
          handleSubmit={(values) => handleSubmit(values, 'CREATE')}
          queryKey={[ADD_PROPERTIES_FEES_BASE_QUERY_KEY]}
          propertyCurrency={propertyCurrency}
          property={property}
          isLoadingProperty={isLoading}
        />
      ),
    });
  };

  const openEditModal = (fee: PropertyInternalFee) => {
    openModal({
      id: EDIT_PROPERTY_FEE_MODAL_ID,
      customContent: (
        <PropertyFeeModal
          fee={fee}
          handleSubmit={(values) => handleSubmit(values, 'EDIT')}
          queryKey={[UPDATE_PROPERTIES_FEES_BASE_QUERY_KEY]}
          propertyCurrency={propertyCurrency}
          property={property}
          isLoadingProperty={isLoading}
        />
      ),
    });
  };

  return {
    openAddModal,
    openEditModal,
  };
};

export default useOpenPropertyFeeModal;
