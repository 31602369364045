import { HasFeeItem, HasOrderItems } from 'models/hasOrderItems/HasOrderItems';
import FormWithProvider from 'components/forms/form/Form';
import { Order } from 'models/Orders';
import { cancelledStatus } from 'utils/lead/leadUtils';
import useOrderViewFetchedData from '../useOrderViewFetchedData';
import OrderViewPriceDetailsInner from './OrderViewPriceDetailsInner';
import { OrderViewPriceDetailsInnerCollapseStyled } from './OrderViewPriceDetails.styles';
import GroupOrderViewPriceDetailsInner from './GroupOrderViewPriceDetailsInner';

const convertOrderToHasOrderItems = (order: Order): HasOrderItems => {
  return {
    ...order,
    fees: {
      otherFees: order.fees.otherFees as unknown as HasFeeItem[],
      cleaningFee: {
        ...(order.fees.cleaningFee as unknown as HasFeeItem),
      },
    },
  };
};

const OrderViewPriceDetailsList = () => {
  const { lead } = useOrderViewFetchedData();

  const order: HasOrderItems = {
    ...convertOrderToHasOrderItems(lead.order),
    cancelled: cancelledStatus.includes(lead.status),
  };

  const subOrders: HasOrderItems[] =
    lead.order?.subOrders
      ?.map((subOrder) => {
        return {
          ...convertOrderToHasOrderItems(subOrder),
          propertyName: subOrder.propertyName,
          cancelled: subOrder.cancelled,
        };
      })
      .sort((a, b) => a.propertyName.localeCompare(b.propertyName)) || [];

  return (
    <FormWithProvider onSubmit={() => {}}>
      {subOrders.length > 0 && (
        <GroupOrderViewPriceDetailsInner
          lead={lead}
          order={order}
          subOrders={subOrders}
          showCancelSubOrder={false}
        />
      )}

      {subOrders.length === 0 && (
        <OrderViewPriceDetailsInner
          lead={lead}
          order={order}
          showAmountPaidRow
          showBalanceRow
        />
      )}

      {subOrders
        .filter((s) => !s.cancelled)
        .map((subOrder) => (
          <OrderViewPriceDetailsInnerCollapseStyled
            key={subOrder.uid}
            data-testid="sub-order-details"
            defaultExpanded={false}
            label={subOrder?.propertyName}
            variant="card"
          >
            <OrderViewPriceDetailsInner
              lead={lead}
              order={subOrder}
              showAmountPaidRow={false}
              showBalanceRow={false}
              showTitle={false}
              handleOrderAdjustment={false}
            />
          </OrderViewPriceDetailsInnerCollapseStyled>
        ))}
    </FormWithProvider>
  );
};

export default OrderViewPriceDetailsList;
