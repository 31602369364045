import { useTranslation } from 'react-i18next';
import { Channel } from 'models/Channels';
import SelectField from 'components/forms/selectField/SelectField';
import { ALL_COMPATIBLE_CHANNELS } from '../PriceRules.constants';

const CHANNEL_OPTIONS = [
  ALL_COMPATIBLE_CHANNELS,
  Channel.orbirental,
  Channel.airbnb,
  Channel.homeaway,
] as const;

const PriceRulesAddEditModalChannelField = () => {
  const { t } = useTranslation();
  const options = CHANNEL_OPTIONS.map((value) => ({
    label: t(
      `pageProperty.pricing.priceRules.modal.form.channelOptions.${value}`,
    ),
    value,
  }));

  return (
    <SelectField
      label={t('pageProperty.pricing.priceRules.modal.form.channel')}
      name="priceRule.channel"
      options={options}
      colSmInput={8}
      colSmLabel={4}
      required
    />
  );
};

export default PriceRulesAddEditModalChannelField;
