import { BreadcrumbItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useAvailableCountries from 'components/domain/country/useAvailableCountries';
import useFetchPropertyTypes from 'components/domain/property/useFetchPropertyTypes';
import useFetchAgency from 'components/domain/agency/useFetchAgency';
import useAppUser from 'hooks/useAppUser';
import usePropertiesCreationAllowed from 'components/domain/property/usePropertiesCreationAllowed';
import FormWithProvider from 'components/forms/form/Form';
import PageHeader from 'components/pageHeader/PageHeader';
import AddPropertyLoadingPlaceholder from '../common/AddPropertyLoadingPlaceholder';
import NotFound from '../../../notFound/NotFound';
import { PropertyPageBreadcrumb } from '../../common/Common.styles';
import {
  addMultiUnitPropertySchema,
  getDefaultFormValues,
} from './AddMultiUnitProperty.schema';
import AddMultiUnitPropertyFormElements from './AddMultiUnitPropertyFormElements';
import useAddMultiUnitPropertySubmit from './useAddMultiUnitPropertySubmit';

const AddMultiUnitProperty = () => {
  const { agencyUid } = useAppUser();
  const { isLoading: isLoadingAvailableCountries } = useAvailableCountries();
  const { isLoading: isLoadingPropertyTypes } = useFetchPropertyTypes();
  const { data: agency, isLoading: isLoadingAgency } = useFetchAgency({
    agencyUid,
  });
  const { t } = useTranslation();

  const onSubmit = useAddMultiUnitPropertySubmit();

  const propertiesCreationAllowed = usePropertiesCreationAllowed();

  if (
    isLoadingAvailableCountries ||
    isLoadingPropertyTypes ||
    isLoadingAgency
  ) {
    return <AddPropertyLoadingPlaceholder />;
  }

  if (!propertiesCreationAllowed) {
    return <NotFound />;
  }

  const pageTitle = t('common.pageTitles.property/add/multi-unit');

  return (
    <div className="container">
      <PropertyPageBreadcrumb>
        <BreadcrumbItem href="#/properties">
          {t('common.pageTitles.properties')}
        </BreadcrumbItem>
        <BreadcrumbItem active>{pageTitle}</BreadcrumbItem>
      </PropertyPageBreadcrumb>
      <PageHeader
        title={pageTitle}
        subtitle={t('pageProperty.add.multiUnit.subtitle')}
        noDivider
      />
      <FormWithProvider
        defaultValues={getDefaultFormValues(agency)}
        resolver={addMultiUnitPropertySchema(t)}
        onSubmit={onSubmit}
        noValidate
      >
        <AddMultiUnitPropertyFormElements />
      </FormWithProvider>
    </div>
  );
};

export default AddMultiUnitProperty;
