import { useLocation } from 'react-router-dom';

const useNavigationMethods = () => {
  const location = useLocation();

  const isNavItemActive = (itemPath: string) => {
    const currentPath = location.pathname;
    return currentPath === itemPath;
  };

  const isDropdownActive = ({ dropdownItems, dropdownSecondaryItems }) => {
    const currentPath = location.pathname;
    // check if currentPath is in dropdownItems and dropdownSecondaryItems
    const isDropdownItem = dropdownItems.some(
      (item) => item.path === currentPath,
    );
    const isDropdownSecondaryItem = dropdownSecondaryItems?.some(
      (item) => item.path === currentPath,
    );

    return isDropdownItem || isDropdownSecondaryItem;
  };

  return { isNavItemActive, isDropdownActive };
};

export default useNavigationMethods;
