import { PhotoDescriptionContainer } from '../PhotoGrid.styles';

const PhotoDescription = ({ description }: { description?: string }) => {
  return (
    <PhotoDescriptionContainer data-testid="photo-description">
      {description}
    </PhotoDescriptionContainer>
  );
};

PhotoDescription.defaultProps = {
  description: '',
};

export default PhotoDescription;
