import * as Yup from 'yup';

export const photoEditModalSchemaResolver = Yup.object().shape({
  caption: Yup.string(),
  tags: Yup.array().of(
    Yup.object({
      name: Yup.string().required(),
    }),
  ),
  mapToRoom: Yup.boolean(),
  airbnbRoomId: Yup.string().nullable(),
});

export type PhotoEditFormValuesType = Yup.InferType<
  typeof photoEditModalSchemaResolver
>;
