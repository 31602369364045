import { useQueryClient } from '@tanstack/react-query';
import { PHOTOS_BASE_QUERY_KEY } from 'components/domain/property/useFetchPhotos';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import { Photo } from 'models/Photos';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import API from 'services/API';
import usePropertyPhotosData from '../../hooks/usePropertyPhotosData';

interface UpdatePhotoOrderParams {
  photo: Photo;
  order: number;
}

export const PHOTO_ORDER_MUTATION_KEY = ['updatePhotoOrder'];

const usePhotoManagement = () => {
  const { uid: propertyUid } = useParams();
  const { t } = useTranslation();
  const { notifyError } = useNotify();
  const { getPhotos } = usePropertyPhotosData();
  const queryClient = useQueryClient();

  const { mutateAsync: updatePhotoOrder } = useAppMutation(
    PHOTO_ORDER_MUTATION_KEY,
    ({ photo, order }: UpdatePhotoOrderParams) => {
      return API.patch(`/api/v3.2/photos/${photo.uid}`, {
        displayOrder: order,
      });
    },
    {
      onMutate: async ({ photo, order }) => {
        await queryClient.cancelQueries({
          queryKey: [PHOTOS_BASE_QUERY_KEY, 'get-all', propertyUid],
        });

        const previousPhotos = getPhotos();

        queryClient.setQueryData(
          [PHOTOS_BASE_QUERY_KEY, 'get-all', propertyUid],
          (old: Photo[] | undefined) => {
            if (!old) return [];
            const updatedPhotos = [...old];
            const sourceIndex = updatedPhotos.findIndex(
              (p) => p.uid === photo.uid,
            );
            const sourcePhoto = updatedPhotos[sourceIndex];

            updatedPhotos.splice(sourceIndex, 1);
            updatedPhotos.splice(order, 0, sourcePhoto);

            updatedPhotos.forEach((p, index) => {
              p.displayOrder = index;
            });

            return updatedPhotos.toSorted(
              (a, b) => a.displayOrder - b.displayOrder,
            );
          },
        );

        return { previousPhotos };
      },
      onError: (err, variables, context) => {
        queryClient.setQueryData(
          [PHOTOS_BASE_QUERY_KEY, 'get-all', propertyUid],
          context?.previousPhotos,
        );
        notifyError(t('pageProperty.photos.updatePhotoOrderError'));
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [PHOTOS_BASE_QUERY_KEY, 'get-all', propertyUid],
        });
      },
    },
  );

  const handleUpdatePhotoOrder = ({ photo, order }: UpdatePhotoOrderParams) => {
    updatePhotoOrder({ photo, order });
  };

  return {
    handleUpdatePhotoOrder,
  };
};

export default usePhotoManagement;
