import { useContext } from 'react';
import useFetchPinCodes from 'components/domain/pinCode/useFetchPinCodes';
import useFetchPropertyChannelSettings from 'components/domain/channelSettings/useFetchPropertyChannelSettings';
import { isLeadAMainBooking } from 'utils/lead/leadUtils';
import {
  propertyCalendarConditionGraphQLQuery,
  propertyCalendarGraphQLQuery,
} from 'pages/stackedCalendar/StackedCalendar.utils';
import { isPropertyDropdownDisabled } from 'utils/property/propertyUtils';
import useFetchLead from '../useFetchLead';
import useFetchTagSuggestions from '../../tag/useFetchTagSuggestions';
import { TagType } from '../../../../models/Tags';
import useFetchAllProperties from '../../property/useFetchAllProperties';
import useFetchEmployees from '../../employee/useFetchEmployees';
import useFetchEntityTags from '../../tag/useFetchEntityTags';
import useFetchReviewByHost from '../../review/useFetchReviewByHost';
import useFetchLeadsForGroupBookings from '../useFetchLeadsForGroupBookings';
import LeadModalContext from './LeadModalContext';
import useFetchAvailableBookingDotComActions from './tabs/actionsTab/useFetchAvailableBookingDotComActions';

const leadFields = `uid,
  address,
  address2,
  adultCount,
  agreementSigned,
  arrivalLocalDateTime,
  assignee{bestProfile, uid},
  availablePipelineActions,
  bookedDateTimeString,
  cellPhoneNumber,
  channel{bestProfile, channel},
  checkInLocalDateTime,
  checkOutLocalDateTime,
  childrenCount,
  city,
  countdown{color, countdownMinutes, countdownPercentage, visualCountdownValue},
  countryCode,
  departureLocalDateTime,
  email,
  firstName,
  hasCompletedPreArrivalForm,
  infantCount,
  lastName,
  notes,
  order{uid, currency, totalAmount, force100PercentPaymentAtReservation, securityDeposit, paymentLinkSecret, paymentLinkUrl, balance, balanceDueLocalDate, includeSecurityDepositInTotal, subOrders {propertyName, uid, cancelled }},
  petCount,
  phoneNumber,
  preferredLocale,
  property{uid, name, businessType, pricing {fullPaymentTiming}},
  reviewByHost{cleanlinessRating, communicationRating, notes, overallRating, respectRulesRating, uid},
  secondaryEmail,
  source,
  state,
  status,
  wereCheckInInstructionsSent,
  zipCode,
  willHaveParty,
  willHaveVisit,
  passportID,
  passportCountryCode,
  customData{uid, name, usage, type, value{text, longText}},
  guest{identificationDocumentUrl, registrationToken, uid},
  extraNotes,
  flightNumber,
  reasonForTrip,
  rentalAgreementURL,
  creationMetadata,
  externalBookingID,
  pinCode{pinCode, pinCodeUrl, uid},
  usePreAuthForDeposits,
  wasSecurityDepositReceived,
  wasSecurityDepositReturned,
  extraGuests{email, firstName, lastName, age},
  fieldsPermissionsMetadata{fieldName,metadata {editable}},
  unitUid,
  bookingDotComData{bookingDotComId,bookingDotComRoomId,rateId,reservationId,roomReservationId},
  duplicateOfLeadUid,
  hotelUid,
  groupUid,
  isGroupBooking, 
  unit{uid, name}
`;

const useLeadModalData = () => {
  const {
    groupBookingLeads,
    availableProperties,
    setAvailableEmployees,
    setAvailableProperties,
    setAvailableTags,
    setLead,
    setLeadTags,
    setPinCode,
    setReviewByHost,
    setChannelSettings,
    setAvailableBookingDotComActions,
    setGroupBookingLeads,
    currentLeadUid: leadUid,
  } = useContext(LeadModalContext);
  const {
    allProperties: fetchedAvailableProperties,
    isFetching: isFetchingAvailableProperties,
  } = useFetchAllProperties({
    enabled: !availableProperties,
    conditions: propertyCalendarConditionGraphQLQuery,
    fields: propertyCalendarGraphQLQuery,
  });
  const { isFetching: isFetchingLead, lead } = useFetchLead({
    leadUid,
    fields: leadFields,
  });
  const { isFetching: isFetchingSubBookingLeads, leads: subBookingLeads } =
    useFetchLeadsForGroupBookings({
      leadUid,
      fields: leadFields,
      enabled: !!leadUid && !!lead && !!isLeadAMainBooking(lead),
    });
  const { isFetching: isFetchingAvailableTags, tags: availableTags } =
    useFetchTagSuggestions(TagType.LEAD, !!leadUid);
  const { isFetching: isFetchingLeadTags, tags: leadTags } = useFetchEntityTags(
    leadUid,
    TagType.LEAD,
    !!leadUid,
  );
  const { isFetching: isFetchingPinCodes, pinCodes } =
    useFetchPinCodes(leadUid);
  const { employees, isFetching: isFetchingAvailableEmployees } =
    useFetchEmployees(!!leadUid);
  const { isFetching: isFetchingReviewByHost, reviewByHost } =
    useFetchReviewByHost(leadUid, !!lead && !lead.reviewByHost);
  const { isFetching: isFetchingChannelSettings, channelSettings } =
    useFetchPropertyChannelSettings(lead?.property.uid);

  const {
    isFetching: isFetchingAvailableChannelActions,
    bookingDotComActions,
  } = useFetchAvailableBookingDotComActions(leadUid);

  const pinCode = pinCodes?.length ? pinCodes[0] : null;

  setAvailableEmployees(employees);
  setAvailableTags(availableTags);
  setLead(lead);
  setLeadTags(leadTags);
  setPinCode(pinCode);
  setReviewByHost(reviewByHost);
  setChannelSettings(channelSettings);
  setAvailableBookingDotComActions(bookingDotComActions);

  if (
    !!lead &&
    !!isLeadAMainBooking(lead) &&
    groupBookingLeads === null &&
    subBookingLeads?.length > 0
  ) {
    setGroupBookingLeads({
      mainLead: lead,
      subLeads: subBookingLeads,
    });
  }

  if (fetchedAvailableProperties) {
    setAvailableProperties(fetchedAvailableProperties);
  }

  return {
    isLoading:
      isFetchingAvailableEmployees ||
      isFetchingAvailableProperties ||
      isFetchingAvailableTags ||
      isFetchingLead ||
      isFetchingLeadTags ||
      isFetchingPinCodes ||
      isFetchingReviewByHost ||
      isFetchingChannelSettings ||
      isFetchingSubBookingLeads,
    isFetchingAvailableChannelActions,
    pinCodes,
    lead,
    leadTags,
    pinCode,
    reviewByHost,
    channelSettings,
    defaultPropertyUid: fetchedAvailableProperties?.filter(
      (property) => !isPropertyDropdownDisabled({ property }),
    )?.[0]?.uid,
    bookingDotComActions,
    // FIXME: this is a temporary solution to get the main booking assignee uid,
    // because the assignee uid is not included in the main booking graphql due to some complications in the API:
    mainBookingAssigneeUid: subBookingLeads?.[0]?.assignee?.uid,
  };
};

export default useLeadModalData;
