import Badge from 'components/badge/Badge';
import { DropdownButton } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import theme from 'styles/theme';

export const imageHeight = '150px';

const photoGridContainerCommonStyles = css`
  display: grid;

  // given Bootstrap's container, it will generate 4 columns at desktop, 3 columns where it fits...
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  ${mediaQuery.xs} {
    // ...and 2 columns on mobile
    grid-template-columns: repeat(2, 1fr);
  }

  gap: 16px;
  padding: 16px;
`;

export const PhotoGridContainer = styled.div<{ $isDisabled?: boolean }>`
  ${photoGridContainerCommonStyles}

  .dragging {
    opacity: 0.5;
    cursor: grabbing;
  }

  .drag-over {
    & img {
      border: 2px dashed ${theme.colors.hostfullyYellow};
      border-radius: 8px;
    }
  }

  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'unset')};
  filter: ${({ $isDisabled }) => ($isDisabled ? 'grayscale(80%)' : 'none')};
  transition: opacity 0.3s ease-in-out;
`;

export const PhotoCardContainer = styled.div<{ $isCover?: boolean }>`
  cursor: grab;

  .photo-card-content {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
  }

  &:hover .photo-card-content {
    outline: 2px solid #3a84d8;
  }

  &:active {
    cursor: grabbing;
  }
`;

export const PhotoImage = styled.img`
  width: 100%;
  height: ${imageHeight};
  object-fit: cover;
`;

export const PhotoCoverBadge = styled(Badge)`
  position: absolute;
  top: 8px;
  left: 8px;

  background-color: ${theme.colors.hostfullyYellow};
  color: #212529;

  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
`;

export const PhotoGridLoaderSkeleton = styled.div`
  ${photoGridContainerCommonStyles}

  font-size: ${imageHeight};
`;

export const PhotoActionsDropdownContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;

  width: 28px;
  height: 28px;
`;

export const PhotoActionsDropdownStyled = styled(DropdownButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;

  width: 28px;
  height: 28px;

  border-radius: 50%;

  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
`;

export const PhotoDescriptionContainer = styled.p`
  margin: 0px;
  padding-top: 8px;
  font-size: 13px;
  line-height: 20px;
  height: 48px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
