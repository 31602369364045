import * as Yup from 'yup';
import { format, parseISO } from 'date-fns';
import { Modal, Row } from 'react-bootstrap';
import { useIsMutating } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';
import { AirbnbGuestReview } from 'models/Reviews';
import StarIcon from 'assets/images/icons/star.svg?react';
import ClockIcon from 'assets/images/icons/clock.svg?react';
import TextAreaField from 'components/forms/textAreaField/TextAreaField';
import FormWithProvider from 'components/forms/form/Form';
import { shouldAllowRespond } from '../GuestReviews.utils';
import {
  OverallRatingStyled,
  ResponseSubmittedOnStyled,
  ResponseTimeExpiredStyled,
} from './ViewAirbnbGuestReviewsModal.styles';
import DisplayField from './DisplayField';
import DetailedFeedback from './detailedFeedback/DetailedFeedback';
import ViewAirbnbGuestReviewModalFooter from './ViewAirbnbGuestReviewModalFooter';

export const VIEW_RESPOND_GUEST_REVIEW_MODAL_ID =
  'view-respond-guest-review-modal';

interface ViewAirbnbGuestReviewModalProps {
  review: AirbnbGuestReview;
  mutationKey: string[];
  onSubmit: (response: string) => void;
}

const schema = () => ({
  response: Yup.string().min(0).max(1000).required(),
});

const ViewAirbnbGuestReviewModal = ({
  review,
  mutationKey,
  onSubmit,
}: ViewAirbnbGuestReviewModalProps) => {
  const { closeModal } = useAppModal();
  const { t } = useTranslation();
  const isMutating = useIsMutating({
    mutationKey,
  });

  const isLoading = isMutating > 0;

  const isRespondActionAllowed = shouldAllowRespond(review);

  const handleClose = () => {
    closeModal(VIEW_RESPOND_GUEST_REVIEW_MODAL_ID);
  };

  const handleSubmit = ({ response }: { response: string }) => {
    onSubmit(response);
  };

  const bookingDates = `${format(
    parseISO(review.bookingDateCheckIn),
    'PP',
  )} - ${format(parseISO(review.bookingDateCheckOut), 'PP')}`;

  const isResponseAlreadySubmitted =
    review.response?.content && review.response?.responseDateTimeUtc;

  return (
    <FormWithProvider
      schema={schema()}
      onSubmit={handleSubmit}
      defaultValues={{
        response: review.response?.content || '',
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('pageProperty.reviews.airbnbGuest.guestReviews.modal.title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DisplayField
          label={t(
            'pageProperty.reviews.airbnbGuest.guestReviews.modal.guestName',
          )}
          value={review.guestName}
          id="guestName"
        />

        <DisplayField
          label={t(
            'pageProperty.reviews.airbnbGuest.guestReviews.modal.bookingDates',
          )}
          value={bookingDates}
          id="bookingDates"
        />

        <DisplayField
          label={t(
            'pageProperty.reviews.airbnbGuest.guestReviews.modal.overallRating',
          )}
          value={
            <OverallRatingStyled>
              <span>{review.rating}</span>
              <StarIcon />
            </OverallRatingStyled>
          }
          id="overallRating"
        />

        <DisplayField
          label={t(
            'pageProperty.reviews.airbnbGuest.guestReviews.modal.publicReview',
          )}
          value={review.publicReview}
          id="publicReview"
        />

        <DisplayField
          label={t(
            'pageProperty.reviews.airbnbGuest.guestReviews.modal.privateNote',
          )}
          value={review.privateNote}
          id="privateNote"
        />

        <DisplayField
          label={t(
            'pageProperty.reviews.airbnbGuest.guestReviews.modal.detailedFeedback',
          )}
          value={<DetailedFeedback review={review} />}
          id="detailedFeedback"
        />

        {isRespondActionAllowed || isResponseAlreadySubmitted ? (
          <>
            <Row>
              <TextAreaField
                colSmLabel={3}
                colSmInput={9}
                label={t(
                  'pageProperty.reviews.airbnbGuest.guestReviews.modal.response',
                )}
                {...(!isResponseAlreadySubmitted && {
                  maxLength: 1000,
                  maxCharacters: 1000,
                })}
                name="response"
                rows={5}
                required
                disabled={isResponseAlreadySubmitted}
              />
            </Row>
            {isResponseAlreadySubmitted && (
              <ResponseSubmittedOnStyled data-testid="response-submitted-on">
                {t(
                  'pageProperty.reviews.airbnbGuest.guestReviews.modal.responseSubmittedOn',
                  {
                    date: format(
                      parseISO(review.response.responseDateTimeUtc),
                      'PP',
                    ),
                  },
                )}
              </ResponseSubmittedOnStyled>
            )}
          </>
        ) : (
          <DisplayField
            label={t(
              'pageProperty.reviews.airbnbGuest.guestReviews.modal.response',
            )}
            value={
              <ResponseTimeExpiredStyled data-testid="response-time-expired">
                <ClockIcon />
                {t(
                  'pageProperty.reviews.airbnbGuest.guestReviews.modal.responseTimeExpired',
                )}
              </ResponseTimeExpiredStyled>
            }
            id="responseTimeExpired"
          />
        )}
      </Modal.Body>

      <ViewAirbnbGuestReviewModalFooter
        handleClose={handleClose}
        isLoading={isLoading}
        isRespondActionAllowed={isRespondActionAllowed}
      />
    </FormWithProvider>
  );
};

export default ViewAirbnbGuestReviewModal;
