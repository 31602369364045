import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import { CountryCode } from 'models/Country';
import API from 'services/API';

export const AVAILABLE_COUNTRIES_BASE_QUERY_KEY = 'available-countries';

interface GetAvailableCountriesResponseTO {
  countries: {
    code: CountryCode;
  }[];
}

type ReactQueryOptions = Omit<
  UseQueryOptions<GetAvailableCountriesResponseTO>,
  'queryKey' | 'queryFn'
>;

const useFetchAvailableCountries = (options: ReactQueryOptions = {}) => {
  return useAppQuery({
    queryKey: [AVAILABLE_COUNTRIES_BASE_QUERY_KEY],
    queryFn: async () => {
      const response = await API.get<GetAvailableCountriesResponseTO>(
        '/api/internal/available-countries',
      );
      return response.data;
    },
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled: true,
    ...(options ?? {}),
  });
};

export default useFetchAvailableCountries;
