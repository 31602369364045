import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';

export interface AirbnbRoom {
  airbnbRoomId: string;
  name: string;
}

export interface GetAirbnbRoomsResponseTO {
  airbnbRooms: AirbnbRoom[];
}

type ReactQueryOptions = Omit<
  UseQueryOptions<GetAirbnbRoomsResponseTO['airbnbRooms']>,
  'queryKey' | 'queryFn'
>;

type Props = {
  propertyUid: string;
} & ReactQueryOptions;

const useAirbnbRooms = ({ propertyUid, enabled = true, ...options }: Props) => {
  const { data: rooms, isInitialLoading: isFetching } = useAppQuery(
    ['airbnb-rooms', propertyUid],
    async (): Promise<AirbnbRoom[]> => {
      const response = await API.get<GetAirbnbRoomsResponseTO>(
        `/v3/airbnb-rooms?propertyUid=${propertyUid}`,
      );
      return response.data?.airbnbRooms;
    },
    {
      enabled: enabled && !!propertyUid,
      cacheTime: 5 * 60 * 1000, // 5 minutes
      ...(options ?? {}),
    },
  );

  return { rooms, isFetching };
};

export default useAirbnbRooms;
