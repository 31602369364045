import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import useDeletePropertyExpectation from 'components/domain/property/expectation/useDeletePropertyExpectation';
import { PROPERTIES_EXPECTATIONS_BASE_QUERY_KEY } from 'components/domain/property/expectation/propertyExpectationQuery';
import TableContext from 'components/table/TableContext';
import useAppModal from 'hooks/useAppModal';
import { PropertyExpectation } from 'models/PropertyExpectations';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import { handlePaginationAfterDelete } from 'utils/pagination';
import useNotify from 'hooks/useNotify';
import BulkDeleteModal, {
  BulkDeleteFormSchemaType,
  bulkDeleteModalId,
} from 'pages/property/feesAndPolicies/BulkDeleteModal';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import { ObjectUidFetchType } from 'models/_base';
import { extractPropertiesMultiSelectFields } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.utils';
import { filterPropertyBy } from './PropertyExpectationAddEditModalAppliesToField';

interface UseDeletePropertyExpectationModalArgs {
  propertyExpectation: PropertyExpectation;
}

const useDeletePropertyExpectationModal = ({
  propertyExpectation,
}: UseDeletePropertyExpectationModalArgs) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { tableInstance } = useContext(TableContext);
  const { uid: propertyUid } = useParams();
  const { openModal, closeModal } = useAppModal();
  const { notifyError } = useNotify();

  const mutationKey = ['property-expectation-delete', propertyExpectation?.uid];

  const { mutateAsync: deletePropertyExpectation } =
    useDeletePropertyExpectation({
      mutationKey,
      onSuccess: () => {
        queryClient.invalidateQueries([
          PROPERTIES_EXPECTATIONS_BASE_QUERY_KEY,
          'list',
          propertyUid,
        ]);

        handlePaginationAfterDelete(tableInstance);

        closeModal(bulkDeleteModalId);
      },
      onError: (error: any) => {
        notifyError(
          getErrorMessage({
            apiErrorCode: error.response.data?.apiErrorCode,
            apiErrorMessage: error.response.data?.apiErrorMessage,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      },
    });

  const handleDelete = async (values: BulkDeleteFormSchemaType) => {
    const { updateAllProperties, propertiesUids } =
      extractPropertiesMultiSelectFields('appliesTo', values);

    const objectUids = updateAllProperties ? [] : propertiesUids;

    await deletePropertyExpectation({
      uid: propertyExpectation?.uid,
      objectUidFetchType: updateAllProperties
        ? ObjectUidFetchType.ALL
        : ObjectUidFetchType.SPECIFIC,
      objectUids,
    });
  };

  const sharedProperties =
    propertyExpectation?.metadata?.propertiesThatAppliesTo?.map(
      ({ uid, name }) => ({
        label: name,
        value: uid,
      }),
    ) ?? [];

  const defaultValues = {
    appliesTo: PROPERTIES_SELECTION.ALL_PROPERTIES,
    appliesToProperties: sharedProperties,
  };

  const openPropertyExpectationDeleteModal = () => {
    openModal({
      id: bulkDeleteModalId,
      customContent: (
        <BulkDeleteModal
          title={t(
            'pageProperty.feesTaxesAndPolicies.propertyExpectations.deleteModal.title',
          )}
          description={t(
            'pageProperty.feesTaxesAndPolicies.propertyExpectations.deleteModal.question',
          )}
          onSubmit={handleDelete}
          defaultValues={defaultValues}
          mutationKey={mutationKey}
          propertyMultiSelectProps={{
            filterPropertyBy,
          }}
        />
      ),
    });
  };

  return {
    openPropertyExpectationDeleteModal,
  };
};

export default useDeletePropertyExpectationModal;
