import { useTranslation } from 'react-i18next';
import PropertyMultiSelectFilterField from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField';
import { PropertyBusinessType } from 'models/Properties';

export const filterPropertyBy = ({ businessType }) =>
  businessType === PropertyBusinessType.STANDALONE_PROPERTY ||
  businessType === PropertyBusinessType.SUB_UNIT;

const PropertyExpectationAddEditModalAppliesToField = () => {
  const { t } = useTranslation();

  const graphQlFilters = { topLevelOnly: false };

  return (
    <PropertyMultiSelectFilterField
      label={t(
        'pageProperty.feesTaxesAndPolicies.propertyExpectations.modal.appliesTo',
      )}
      name="appliesTo"
      filterPropertyBy={filterPropertyBy}
      graphQlFilters={graphQlFilters}
      colSmInput={8}
      colSmLabel={4}
      filtersColXs={12}
    />
  );
};

export default PropertyExpectationAddEditModalAppliesToField;
