import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAll } from 'store/store.constants';
import {
  CalendarBodyRefreshRequest,
  LoadedDateRange,
} from '../../../pages/stackedCalendar/Calendar.types';
import updatePropertiesEntriesReducer from './reducers/updatePropertiesEntriesReducer';
import updateVisiblePropertiesReducer from './reducers/updateVisiblePropertiesReducer';
import { StackedCalendarState } from './types';

const initialState: StackedCalendarState = {
  bodyRefreshRequest: undefined,
  isLoadingProperties: true,
  loadedDateRange: undefined,
  nextPropertiesOffset: 0,
  propertiesEntries: {},
  visibleDates: [],
  visibleProperties: [],
};

const stackedCalendarSlice = createSlice({
  name: 'stackedCalendar',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    addVisibleProperties: (
      state: StackedCalendarState,
      {
        payload: { index, properties },
      }: PayloadAction<{ properties: string[]; index: number }>,
    ) => {
      state.visibleProperties.splice(index, 0, ...properties);
    },
    removeVisibleProperties: (
      state: StackedCalendarState,
      { payload: { properties } }: PayloadAction<{ properties: string[] }>,
    ) => {
      state.visibleProperties = state.visibleProperties.filter(
        (uid) => !properties.includes(uid),
      );
    },
    resetPropertiesEntries: (state: StackedCalendarState) => {
      state.propertiesEntries = {};
    },
    resetStackedCalendarState: () => initialState,
    setBodyRefreshRequest: (
      state: StackedCalendarState,
      {
        payload: bodyRefreshRequest,
      }: PayloadAction<CalendarBodyRefreshRequest>,
    ) => {
      state.bodyRefreshRequest = bodyRefreshRequest;
    },
    setIsLoadingProperties: (
      state: StackedCalendarState,
      { payload: isLoadingProperties }: PayloadAction<boolean>,
    ) => {
      state.isLoadingProperties = isLoadingProperties;
    },
    setLoadedDateRange: (
      state: StackedCalendarState,
      { payload: loadedDateRange }: PayloadAction<LoadedDateRange>,
    ) => {
      state.loadedDateRange = loadedDateRange;
    },
    setNextPropertiesOffset: (
      state: StackedCalendarState,
      { payload: nextPropertiesOffset }: PayloadAction<number>,
    ) => {
      state.nextPropertiesOffset = nextPropertiesOffset;
    },
    setVisibleDates: (
      state: StackedCalendarState,
      { payload: visibleDates }: PayloadAction<Date[]>,
    ) => {
      state.visibleDates = visibleDates;
    },
    updatePropertiesEntries: updatePropertiesEntriesReducer,
    updateVisibleProperties: updateVisiblePropertiesReducer,
  },
});

export const {
  addVisibleProperties,
  removeVisibleProperties,
  resetPropertiesEntries,
  resetStackedCalendarState,
  setBodyRefreshRequest,
  setIsLoadingProperties,
  setLoadedDateRange,
  setNextPropertiesOffset,
  setVisibleDates,
  updatePropertiesEntries,
  updateVisibleProperties,
} = stackedCalendarSlice.actions;

export default stackedCalendarSlice.reducer;
