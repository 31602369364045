import Button from 'components/button/Button';
import useFetchPropertyChannelSettings from 'components/domain/channelSettings/useFetchPropertyChannelSettings';
import useFetchEntityTags from 'components/domain/tag/useFetchEntityTags';
import FormWithProvider from 'components/forms/form/Form';
import { Channel } from 'models/Channels';
import { Photo } from 'models/Photos';
import { TagType } from 'models/Tags';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import usePhotoMutation from '../usePhotoMutation';
import useAirbnbRooms from './useAirbnbRooms';
import PhotoEditModalLoader from './PhotoEditModalLoader';
import PhotoEditModalFields from './PhotoEditModalFields';
import { PhotoEditModalFooter, PhotoImage } from './PhotoEditModal.styles';
import {
  PhotoEditFormValuesType,
  photoEditModalSchemaResolver,
} from './PhotoEditModal.schema';

const PhotoEditModal = ({ photo }: { photo: Photo }) => {
  const { t } = useTranslation();

  const { channelSettings, isFetching: isFetchingChannelSettings } =
    useFetchPropertyChannelSettings(photo?.propertyUid);

  const isAirbnbActive = !!channelSettings?.find(
    ({ enumId }) => enumId === Channel.airbnb,
  )?.isActive;

  const { tags, isFetching: isFetchingTags } = useFetchEntityTags(
    photo?.uid,
    TagType.PHOTO,
    true,
  );

  const { rooms = [], isFetching: isFetchingRooms } = useAirbnbRooms({
    propertyUid: photo?.propertyUid,
    enabled: isAirbnbActive,
  });

  const { isUpdatingPhoto, updatePhoto } = usePhotoMutation();

  const isFetching =
    isFetchingChannelSettings || isFetchingTags || isFetchingRooms;

  const defaultValues = {
    tags,
    mapToRoom: photo?.airbnbRoomId != null,
    airbnbRoomId: photo?.airbnbRoomId,
    caption: photo?.description,
  };

  const airbnbRoomOptions = [
    {
      label: t('pageProperty.photos.editModal.selectRoom'),
      value: null,
    },
    ...rooms.map((room) => ({
      label: room.name,
      value: room.airbnbRoomId,
    })),
  ];

  const handleSubmit = (values: PhotoEditFormValuesType) => {
    updatePhoto({
      updatedPhoto: {
        airbnbRoomId: values.mapToRoom ? values.airbnbRoomId : null,
        description: values.caption,
      },
      photoUid: photo?.uid,
    });
  };

  if (isFetching) {
    return <PhotoEditModalLoader />;
  }

  return (
    <FormWithProvider
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      resolver={photoEditModalSchemaResolver}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('pageProperty.photos.editModal.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <PhotoImage src={photo.originalImageUrl} />

        <PhotoEditModalFields
          isAirbnbActive={isAirbnbActive}
          airbnbRoomOptions={airbnbRoomOptions}
        />
      </Modal.Body>

      <PhotoEditModalFooter>
        <Button bsStyle="danger" onClick={() => {}}>
          {t('common.delete')}
        </Button>
        <Button bsStyle="primary" type="submit" disabled={isUpdatingPhoto}>
          {t('common.save')}
        </Button>
      </PhotoEditModalFooter>
    </FormWithProvider>
  );
};

export default PhotoEditModal;
