import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import Tooltip from 'components/tooltip/Tooltip';
import { Col, Glyphicon } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IsOptionalFieldRow } from './PropertyFeeModal.styles';

const IsOptionalField = () => {
  const { t } = useTranslation();

  return (
    <IsOptionalFieldRow>
      <Col xs={6} xsOffset={4}>
        <CheckboxField name="optional">
          {t(
            'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.feeIsOptional',
          )}
        </CheckboxField>
      </Col>

      <Col xs={2}>
        <Tooltip
          id="fee-is-optional-tooltip"
          text={t(
            'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.feeOptionalTooltip',
          )}
        >
          <Glyphicon glyph="info-sign" />
        </Tooltip>
      </Col>
    </IsOptionalFieldRow>
  );
};

export default IsOptionalField;
