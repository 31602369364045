import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { startOfToday, startOfTomorrow } from 'date-fns';
import useOpenLeadModal from 'components/domain/lead/modal/useOpenLeadModal';
import useAppDispatch from '../hooks/useAppDispatch';
import { refreshAppConfig } from '../store/slices/config';
import {
  AppEventType,
  LeadCreatedEventPayload,
  LeadDeletedEventPayload,
  LeadUpdatedEventPayload,
} from '../models/Events';
import useAppEvent from '../hooks/useAppEvent';

const WindowMessagesHandler = () => {
  const dispatch = useAppDispatch();
  const { subscribe, unsubscribe } = useAppEvent();
  const navigate = useNavigate();
  const { createLeadModal, updateLeadModal } = useOpenLeadModal();

  const postMessageToIframe = (message) => {
    const legacyFrameElement = document.querySelector(
      'iframe#legacy-page-frame',
    );

    if (legacyFrameElement) {
      // @ts-ignore
      legacyFrameElement.contentWindow?.postMessage(message, '*');
    }
  };

  // message listener needs to be memoized in order to have it added only once
  const onMessageReceived = useCallback(
    ({
      data: {
        leadModalOpenRequest,
        redirectUrl,
        redirectToNewLogin,
        whiteLabelingSettingsSaved,
      },
    }) => {
      if (whiteLabelingSettingsSaved) {
        dispatch(refreshAppConfig());
      }

      if (redirectUrl) {
        navigate(redirectUrl);
      }
      if (redirectToNewLogin) {
        navigate('/logout'); // in case of a login page redirection from pmp-core it must be assured that tokens are removed
      }

      if (leadModalOpenRequest) {
        const { leadUid, leadForRebookingUid } = leadModalOpenRequest;

        if (leadUid) {
          updateLeadModal({ jobCreationNotAllowed: true, leadUid });
        } else {
          createLeadModal({
            dateFrom: startOfToday(),
            dateTo: startOfTomorrow(),
            leadForRebookingUid,
            jobCreationNotAllowed: true,
          });
        }
      }
    },
    [dispatch, navigate],
  );

  useEffect(() => {
    if (!window.frameElement) {
      window.addEventListener('message', onMessageReceived);

      const leadCreatedListener = ({
        detail: {
          lead: { uid, status },
        },
      }: CustomEvent<LeadCreatedEventPayload>) => {
        postMessageToIframe({
          leadAlterationRequest: {
            createdLeadUid: uid,
            createdLeadStatus: status,
          },
        });
      };
      const leadDeletedListener = ({
        detail: { leadUid },
      }: CustomEvent<LeadDeletedEventPayload>) => {
        postMessageToIframe({
          leadAlterationRequest: { deletedLeadUid: leadUid },
        });
      };
      const leadUpdatedListener = ({
        detail: {
          lead: { uid },
        },
      }: CustomEvent<LeadUpdatedEventPayload>) => {
        postMessageToIframe({ leadAlterationRequest: { updatedLeadUid: uid } });
      };

      subscribe(AppEventType.LEAD_CREATED, leadCreatedListener);
      subscribe(AppEventType.LEAD_DELETED, leadDeletedListener);
      subscribe(AppEventType.LEAD_UPDATED, leadUpdatedListener);

      return () => {
        unsubscribe(AppEventType.LEAD_CREATED, leadCreatedListener);
        unsubscribe(AppEventType.LEAD_DELETED, leadDeletedListener);
        unsubscribe(AppEventType.LEAD_UPDATED, leadUpdatedListener);
      };
    }

    return () => {};
  }, [onMessageReceived]);

  return null;
};

export default WindowMessagesHandler;
