import styled from 'styled-components';
import { breakpoints } from 'styles/mediaQuery';
import { Container as SwitchContainer } from 'components/switch/Switch.styles';
import { ModalFooter } from 'react-bootstrap';

export const PhotoEditModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  textarea {
    resize: vertical;
  }
`;

export const PhotoImage = styled.img`
  width: 100%;
  height: 600px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 16px;

  @media (max-width: ${breakpoints.SM}px) {
    height: 220px;
  }
`;

export const TagsContainer = styled.div`
  .tags-input {
    padding-left: 0;
  }

  .tags-label {
    text-align: right;
    font-weight: 700;
  }

  .form-group {
    margin-bottom: 0;
  }

  .rbt-input {
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .rbt-input-wrapper {
    margin-bottom: 0;
    gap: 8px;
  }

  .rbt-token {
    height: 23px;
    background-color: #f8f9fa;
    color: #212529;
    border: 1px solid #ced4da;
    margin: 1px;
  }
`;

export const ChannelSettingsSeparator = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 12 0px;

  &::after {
    content: '';
    flex: 1;
    height: 1px;
    background-color: #21252926;
    margin-left: 16px;
  }
`;

export const SwitchFieldContainer = styled.div`
  display: flex;
  align-items: center;
  height: 34px;

  .map-to-room-label {
    text-align: right;
    font-weight: 700;
  }

  .switch-field {
    scale: 0.5;
  }

  .toggle-switch {
    display: flex;
    margin: 0;
  }

  .checkbox {
    margin: 0;
  }

  .toggle-switch:checked {
    background-color: #3a84d8;
  }

  .form-group {
    margin-bottom: 0;
  }

  .airbnbRoomId-field {
    padding-left: 0;
    padding-right: 0;
  }

  ${SwitchContainer} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PhotoEditModalFooter = styled(ModalFooter)`
  & > .btn-danger {
    float: left;
  }
`;
