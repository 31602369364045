import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import CommonTable from 'components/table/CommonTable';
import useTable from 'components/table/hooks/useTable';
import { CancellationPolicy } from 'models/CancellationPolicy';
import { LanguageCodeUnderscored } from 'i18n/internationalization.types';
import useCancellationPolicyData from './useCancellationPolicyData';
import ActionsCell from './cells/ActionsCell';

const CancellationPolicyTable = () => {
  const { t } = useTranslation();
  const { cancellationPolicyData, isLoadingCancellationPolicy } =
    useCancellationPolicyData();

  // pmp-core is saving locale as en_US, so we need to get the correct value
  const englishCancellationPolicyValue = cancellationPolicyData?.values?.find(
    (value) => value.locale === LanguageCodeUnderscored.EN_US,
  );

  const cancellationPolicy: CancellationPolicy = englishCancellationPolicyValue
    ? {
        ...cancellationPolicyData,
        values: [englishCancellationPolicyValue],
      }
    : null;

  const columnHelper = createColumnHelper<CancellationPolicy>();

  const columns = [
    columnHelper.accessor('values.text', {
      id: 'cancellationPolicy',
      header: t('pageProperty.feesTaxesAndPolicies.cancellationPolicy.text'),
      enableSorting: false,
      cell: (data) => data.row.original?.values?.[0]?.text,
    }),
    columnHelper.display({
      id: 'actions',
      header: t('pageProperty.feesTaxesAndPolicies.cancellationPolicy.actions'),
      cell: ActionsCell,
    }),
  ];

  const tableInstance = useTable({
    // We always have only one cancellation policy
    data: cancellationPolicy ? [cancellationPolicy] : [],
    columns,
    manualPagination: true,
    pageCount: 1,
  });

  return (
    <CommonTable
      tableInstance={tableInstance}
      isLoading={isLoadingCancellationPolicy}
      data-testid="cancellation-policy-table"
      emptyState={t(
        'pageProperty.feesTaxesAndPolicies.cancellationPolicy.emptyTable',
      )}
    />
  );
};

export default CancellationPolicyTable;
