import * as Yup from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import { Channel } from 'models/Channels';
import { PropertyPricingType } from 'models/pricing/PropertyPricingType';
import { Property } from 'models/Properties';
import { ALL_COMPATIBLE_CHANNELS } from '../PriceRules.constants';

export const priceRulesAddEditFormSchema = () => ({
  appliesTo: Yup.string().required(),
  appliesToProperties: Yup.array()
    .of(
      Yup.object({
        isFixed: Yup.boolean().optional(),
        label: Yup.string(),
        value: Yup.string(),
      }),
    )
    .label('properties')
    .when('appliesTo', {
      is: PROPERTIES_SELECTION.ALL_PROPERTIES,
      then: (schema) => schema.optional().nullable(true),
      otherwise: (schema) => schema.min(1).required(),
    }),
  appliesToStatus: Yup.mixed().optional().strip(),
  appliesToTags: Yup.mixed().optional().strip(),
  priceRule: Yup.object({
    adjustPrice: Yup.number()
      .useNaNAsNull()
      .useNegativeZeroAsNegative()
      .min(0)
      .max(100)
      .required(),
    channel: Yup.mixed<typeof ALL_COMPATIBLE_CHANNELS | Channel>()
      .oneOf([ALL_COMPATIBLE_CHANNELS, ...Object.values(Channel)])
      .required(),
    name: Yup.string().required(),
    threshold: Yup.number()
      .useNaNAsNull()
      .useNegativeZeroAsNegative()
      .min(0)
      .required(),
    type: Yup.mixed<PropertyPricingType>()
      .oneOf(Object.values(PropertyPricingType))
      .required(),
  }),
});

export type PriceRulesAddEditFormValues = Yup.InferType<
  OptionalObjectSchema<ReturnType<typeof priceRulesAddEditFormSchema>>
>;

export const getFormDefaultValues = ({
  property,
}: {
  property: Property;
}): PriceRulesAddEditFormValues => {
  return {
    appliesTo: PROPERTIES_SELECTION.SELECTED_PROPERTIES,
    appliesToProperties: [
      {
        isFixed: true,
        label: property?.name,
        value: property?.uid,
      },
    ],
    appliesToStatus: undefined,
    appliesToTags: undefined,
    priceRule: {
      adjustPrice: 0,
      channel: ALL_COMPATIBLE_CHANNELS,
      name: '',
      threshold: 0,
      type: PropertyPricingType.LOS_DISCOUNT,
    },
  };
};
