import { useTranslation } from 'react-i18next';
import usePropertiesUpdatingAllowed from 'components/domain/property/usePropertiesUpdatingAllowed';
import FormWithProvider from 'components/forms/form/Form';
import { CollapseSectionStyled } from 'pages/property/common/Common.styles';
import {
  getFormDefaultValues,
  propertySettingsPricingTabSchema,
} from 'pages/property/pricing/PropertySettingsPricingTab.schema';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import PriceSettingsLoader from './PriceSettingsLoader';
import PriceSettingsFormElements from './PriceSettingsFormElements';

const PriceSettings = () => {
  const { t } = useTranslation();
  const { isLoading: isLoadingInitData } = usePropertySettingsInitData();
  const propertiesUpdatingAllowed = usePropertiesUpdatingAllowed();

  const isLoading = isLoadingInitData;

  if (isLoading) {
    return <PriceSettingsLoader />;
  }

  return (
    <FormWithProvider
      defaultValues={getFormDefaultValues()}
      disabled={!propertiesUpdatingAllowed}
      resolver={propertySettingsPricingTabSchema()}
      onSubmit={() => {}}
      dirtyChecker
      noValidate
    >
      <CollapseSectionStyled
        data-testid="price-settings"
        defaultExpanded
        label={t('pageProperty.pricing.priceSettings.title')}
        variant="card"
      >
        <PriceSettingsFormElements />
      </CollapseSectionStyled>
    </FormWithProvider>
  );
};

export default PriceSettings;
