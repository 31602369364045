import { useTranslation } from 'react-i18next';
import { Photo } from 'models/Photos';
import useNotify from 'hooks/useNotify';
import useAppMutation from 'hooks/useAppMutation';
import API from 'services/API';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import useAppModal from 'hooks/useAppModal';
import useInvalidatePropertyPhotos from 'pages/property/photos/hooks/useInvalidatePropertyPhotos';
import { PhotoEditModalId } from './editPhoto/PhotoEditModal.constants';

const usePhotoMutation = () => {
  const { t } = useTranslation();
  const { notifyError } = useNotify();
  const { closeModal } = useAppModal();
  const { invalidatePropertyPhotos } = useInvalidatePropertyPhotos();

  const { mutateAsync: updatePhoto, isLoading: isUpdatingPhoto } =
    useAppMutation(
      ({
        updatedPhoto,
        photoUid,
      }: {
        updatedPhoto: Partial<Photo>;
        photoUid: string;
      }) => {
        return API.patch(`/api/v3.2/photos/${photoUid}`, {
          ...updatedPhoto,
        });
      },
      {
        onSuccess: () => {
          invalidatePropertyPhotos();

          closeModal(PhotoEditModalId);
        },
        onError: (error: any) => {
          notifyError(
            getErrorMessage({
              apiErrorCode: error.response.data?.apiErrorCode,
              apiErrorMessage: error.response.data?.apiErrorMessage,
              baseKey: 'pageProperty.apiErrorCodes.',
              t,
            }),
          );
        },
      },
    );

  return {
    updatePhoto,
    isUpdatingPhoto,
  };
};

export default usePhotoMutation;
