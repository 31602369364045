import { useIsMutating } from '@tanstack/react-query';
import Button from 'components/button/Button';
import PropertyMultiSelectFilterField from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import FormWithProvider from 'components/forms/form/Form';
import TextField from 'components/forms/textField/TextField';
import useAppModal from 'hooks/useAppModal';
import { HouseRule } from 'models/HouseRules';
import { Property } from 'models/Properties';
import { AppliesToLabel } from 'pages/property/common/Common.styles';
import { Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';

export const houseRuleModalId = 'house-rule-modal';

const formSchema = () => ({
  appliesTo: Yup.string().required(),
  appliesToProperties: Yup.array()
    .of(
      Yup.object({
        label: Yup.string(),
        value: Yup.string(),
        isFixed: Yup.boolean().optional(),
      }),
    )
    .label('properties')
    .when('appliesTo', {
      is: PROPERTIES_SELECTION.ALL_PROPERTIES,
      then: (schema) => schema.optional().nullable(true),
      otherwise: (schema) => schema.min(1).required(),
    }),
  description: Yup.string().max(38000).required(),
});

export type HouseRulesFormSchemaType = Yup.InferType<
  OptionalObjectSchema<ReturnType<typeof formSchema>>
>;

const HouseRulesModal = ({
  onSubmit,
  houseRule,
  property,
  mutationKey,
}: {
  onSubmit: (data: HouseRulesFormSchemaType) => void;
  houseRule?: HouseRule;
  property: Property;
  mutationKey: string[];
}) => {
  const { closeModal } = useAppModal();
  const { t } = useTranslation();
  const isMutating = useIsMutating({
    mutationKey,
  });

  const isLoading = isMutating > 0;
  const isEdit = !!houseRule?.uid;

  const handleClose = () => {
    closeModal(houseRuleModalId);
  };

  const getDefaultValues = () => {
    if (isEdit) {
      const { description, metadata } = houseRule;

      return {
        description,
        appliesTo: metadata?.appliesToAllProperties
          ? PROPERTIES_SELECTION.ALL_PROPERTIES
          : PROPERTIES_SELECTION.SELECTED_PROPERTIES,
        appliesToProperties: metadata?.appliesToAllProperties
          ? []
          : metadata?.propertiesThatAppliesTo?.map(({ uid, name }) => ({
              label: name,
              value: uid,
              isFixed: uid === property.uid,
            })),
      };
    }

    return {
      appliesTo: PROPERTIES_SELECTION.SELECTED_PROPERTIES,
      appliesToProperties: [
        {
          label: property.name,
          value: property.uid,
          isFixed: true,
        },
      ],
    };
  };

  return (
    <FormWithProvider
      horizontal
      schema={formSchema()}
      onSubmit={onSubmit}
      defaultValues={getDefaultValues()}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isEdit
            ? t('pageProperty.feesTaxesAndPolicies.houseRules.modal.editTitle')
            : t('pageProperty.feesTaxesAndPolicies.houseRules.modal.addTitle')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <TextField
              name="description"
              label={t(
                'pageProperty.feesTaxesAndPolicies.houseRules.modal.description',
              )}
              componentClass="textarea"
              required
              colSmLabel={2}
              colSmInput={10}
              rows={6}
              maxLength={38000}
              maxCharacters={500}
              maxCharactersDescription={t(
                'pageProperty.feesTaxesAndPolicies.houseRules.modal.maxCharactersDescription',
              )}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={2}>
            <AppliesToLabel>
              {t('pageProperty.common.cells.appliesTo')}
            </AppliesToLabel>
          </Col>

          <Col xs={10}>
            <PropertyMultiSelectFilterField
              name="appliesTo"
              enableHotels={false}
              enableUnitTypes
            />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button bsStyle="default" onClick={handleClose}>
          {t('common.cancel')}
        </Button>
        <Button bsStyle="primary" type="submit" disabled={isLoading}>
          {t('common.save')}
        </Button>
      </Modal.Footer>
    </FormWithProvider>
  );
};

HouseRulesModal.defaultProps = {
  houseRule: undefined,
};

export default HouseRulesModal;
